import { Button, TextField, Typography } from '@material-ui/core';
import Auth from '@aws-amplify/auth';
import { Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import Layout, { useStyles } from './Layout';

const SignUp: React.FC<{}> = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        password: '',
        passwordConfirmation: '',
      }}
      initialStatus={null}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting, setFieldError, setStatus }) => {
        setSubmitting(true);
        setStatus(null);
        try {
          await Auth.signUp({
            username: values.email,
            password: values.password,
            attributes: {
              name: values.name,
              email: values.email.toLowerCase(),
            },
          });
          setSubmitting(false);
          history.push({
            pathname: '/signup/confirm',
            search: `?email=${values.email}`,
          });
        } catch (err) {
          if (err.code === 'InvalidPasswordException') {
            setFieldError('password', err.message);
          } else if (err.code === 'UsernameExistsException') {
            setFieldError('email', '');
            setStatus(new Error(`An user with this email already exists`));
          } else {
            // Unhandled error, show it row to the user
            setStatus(err);
          }

          setSubmitting(false);
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('A valid email is required')
          .required('Required'),
        password: Yup.string()
          .min(8, '8 characters minimum')
          .required('Required'),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Required'),
        name: Yup.string().required('Required'),
      })}
    >
      {props => {
        const {
          handleSubmit,
          values,
          errors,
          handleChange,
          handleBlur,
          dirty,
          isSubmitting,
          isValid,
          status,
        } = props;

        return (
          <Layout title="Sign up">
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                disabled={isSubmitting}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!!errors.password}
                onChange={handleChange}
                disabled={isSubmitting}
                onBlur={handleBlur}
                value={values.password}
                helperText={errors.password}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="passwordConfirmation"
                label="Password confirmation"
                type="password"
                id="passwordConfirmation"
                autoComplete="current-password"
                error={!!errors.passwordConfirmation}
                onChange={handleChange}
                disabled={isSubmitting}
                onBlur={handleBlur}
                value={values.passwordConfirmation}
                helperText={errors.passwordConfirmation}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="name"
                label="Name"
                id="name"
                autoComplete="name"
                error={!!errors.name}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                helperText={errors.name}
              />
              {status && (
                <Typography className={classes.globalError} variant="body2">
                  {status.message}
                </Typography>
              )}
              <div className={classes.actionsContainer}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting || !isValid}
                  className={classes.submitButton}
                >
                  Sign Up
                </Button>
              </div>
            </form>
          </Layout>
        );
      }}
    </Formik>
  );
};

export default SignUp;
