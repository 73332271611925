import React from 'react';
import { Typography } from '@material-ui/core';
import Unauthorized from './pages/Unauthorized';

type AWSError = import('aws-sdk').AWSError;

type State = { error: null | Error | AWSError };

class AuthorizationErrorCatcher extends React.Component<{}, State> {
  state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: Error | AWSError): State {
    return { error };
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!error) {
      return children;
    }

    if ('code' in error && error.code === 'NotAuthorizedException') {
      return <Unauthorized />;
    }

    return (
      <React.Fragment>
        <Typography variant="h3" align="center">
          An error happened
        </Typography>
        <Typography variant="body1" paragraph>
          {error.message}
        </Typography>
      </React.Fragment>
    );
  }
}

export default AuthorizationErrorCatcher;
