import React from 'react';
import { ThemeProvider as MuiThemer } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from './theme';

function ThemeProvider({ children }: { children?: React.ReactNode }) {
  return (
    <MuiThemer theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemer>
  );
}

export default ThemeProvider;
