import { Button, TextField, Typography } from '@material-ui/core';
import Auth from '@aws-amplify/auth';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Done, Layout, useStyles } from './Layout';

const ChangePassword: React.FC<{}> = props => {
  const classes = useStyles();

  const [done, setDone] = useState<boolean>(false);

  if (done) {
    return (
      <Layout title="Change password">
        <Done>
          <Typography align="justify" variant="body2">
            Your password has been changed.
          </Typography>
        </Done>
      </Layout>
    );
  }

  return (
    <Layout title="Change password">
      <Formik
        initialValues={{
          currentPassword: '',
          password: '',
          passwordConfirmation: '',
        }}
        initialStatus={null as null | Error}
        onSubmit={async (
          values,
          { setSubmitting, setFieldError, setStatus },
        ) => {
          setSubmitting(true);
          setStatus(null);
          try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(
              currentUser,
              values.currentPassword,
              values.password,
            );

            setSubmitting(false);
            setDone(true);
          } catch (err) {
            if (err.code === 'NotAuthorizedException') {
              setFieldError('currentPassword', 'Invalid password');
            } else if (err.code === 'InvalidPasswordException') {
              setFieldError('password', err.message);
            } else {
              // Unhandled error, show it raw to the user
              setStatus(err);
            }

            setSubmitting(false);
          }
        }}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string().required('Required'),
          password: Yup.string()
            .min(8, '8 characters minimum')
            .required('Required'),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Required'),
        })}
      >
        {formikProps => {
          const {
            handleSubmit,
            values,
            errors,
            handleChange,
            handleBlur,
            isSubmitting,
            status,
          } = formikProps;

          return (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="password"
                name="currentPassword"
                label="Current password"
                id="currentPassword"
                autoComplete="password"
                error={!!errors.currentPassword}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.currentPassword}
                helperText={errors.currentPassword}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="password"
                name="password"
                label="New password"
                id="password"
                autoComplete="new-password"
                error={!!errors.password}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                helperText={errors.password}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="password"
                name="passwordConfirmation"
                label="New password (confirmation)"
                id="passwordConfirmation"
                autoComplete="new-password"
                error={!!errors.passwordConfirmation}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.passwordConfirmation}
                helperText={errors.passwordConfirmation}
              />

              {status && (
                <Typography className={classes.globalError} variant="body2">
                  {status.message}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                className={classes.submitButton}
              >
                Reset password
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default ChangePassword;
