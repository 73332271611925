import React, { useState } from 'react';
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Box,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useTrafficVolumes } from './useTrafficVolumes';

const useClasses = makeStyles((theme: Theme) => ({
  editIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const TVTitle: React.FC<{
  when: Date;
  center: string;
  onSelectTv?: (trafficVolume: string) => any;
  subtitle?: React.ReactNode;
}> = ({ when, center, children, onSelectTv, subtitle }) => {
  const tv = useTrafficVolumes({ center, when });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useClasses();

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h3" gutterBottom={!subtitle}>
        {children}
        {onSelectTv && (
          <React.Fragment>
            <IconButton
              aria-controls="tv-selection-menu"
              aria-haspopup="true"
              disabled={tv.loading || !tv.records || tv.records.length === 0}
              onClick={event => setAnchorEl(event.currentTarget)}
              className={classes.editIcon}
            >
              <EditIcon />
            </IconButton>
            <Menu
              id="tv-selection-menu"
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              {(tv.records || []).map(tv => (
                <MenuItem
                  key={tv}
                  onClick={() => {
                    setAnchorEl(null);
                    onSelectTv(tv);
                  }}
                >
                  {tv}
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        )}
      </Typography>
      {subtitle && (
        <Typography color="textSecondary" variant="h5" gutterBottom>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default TVTitle;
