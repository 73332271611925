import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  fade,
} from '@material-ui/core';
import { grey, indigo, pink, purple } from '@material-ui/core/colors';
import LabelIcon from '@material-ui/icons/Label';
import moment from 'moment';
import React, { useState, useMemo } from 'react';
import { SizeMe } from 'react-sizeme';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryTheme,
} from 'victory';
import Center from '../Center';

const baseColors = {
  LOAD: indigo[500],
  DEMAND: purple[500],
  REGULATED_DEMAND: pink[500],
};

const useClasses = makeStyles({
  unselected: {
    color: grey['A200'],
  },
  load: {
    color: baseColors.LOAD,
  },
  demand: {
    color: baseColors.DEMAND,
  },
  regulatedDemand: {
    color: baseColors.REGULATED_DEMAND,
  },
});

const Graph: React.FC<{
  content: null | object;
  compare: null | object;
  loading?: boolean;
}> = ({ content, compare, loading }) => {
  const [dataTypes, setDataTypes] = useState<
    Array<'LOAD' | 'DEMAND' | 'REGULATED_DEMAND'>
  >(['LOAD', 'DEMAND']);

  const classes = useClasses();

  const transformedBaseData = useMemo(() => {
    if (!content) {
      return null;
    }

    return (content as any).counts.item.map(({ key, value }: any) => ({
      x: key.wef,
      LOAD: value.item.find(({ key }: any) => key === 'LOAD').value.totalCounts,
      DEMAND: value.item.find(({ key }: any) => key === 'DEMAND').value
        .totalCounts,
      REGULATED_DEMAND: value.item.find(
        ({ key }: any) => key === 'REGULATED_DEMAND',
      ).value.totalCounts,
    }));
  }, [content]);

  const transformedCompareData = useMemo(() => {
    if (!compare || !transformedBaseData) {
      return null;
    }

    return (compare as any).counts.item.map(
      ({ key, value }: any, idx: number) => ({
        x: transformedBaseData[idx].x,
        LOAD: value.item.find(({ key }: any) => key === 'LOAD').value
          .totalCounts,
        DEMAND: value.item.find(({ key }: any) => key === 'DEMAND').value
          .totalCounts,
        REGULATED_DEMAND: value.item.find(
          ({ key }: any) => key === 'REGULATED_DEMAND',
        ).value.totalCounts,
      }),
    );
  }, [compare, transformedBaseData]);

  const colorScale = useMemo(
    () =>
      (['LOAD', 'DEMAND', 'REGULATED_DEMAND'] as Array<
        'LOAD' | 'DEMAND' | 'REGULATED_DEMAND'
      >).flatMap(d => {
        if (!dataTypes.includes(d)) {
          return [] as Array<string>;
        }

        if (!transformedCompareData) {
          return baseColors[d];
        }

        return [baseColors[d], fade(baseColors[d], 0.4)];
      }),
    [dataTypes, transformedCompareData],
  );

  if (!transformedBaseData) {
    return (
      <Center>
        <Typography variant="h3">Nothing could be found :(</Typography>
      </Center>
    );
  }

  const visibleBars =
    dataTypes.length + (!!transformedCompareData ? dataTypes.length : 0);

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexGrow={1} />
        <Grid item xs={12} sm={3}>
          <Box paddingTop={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="load_type">Data type</InputLabel>
              <Select
                disabled={loading}
                value={dataTypes}
                multiple
                renderValue={selected => (selected as string[]).join(', ')}
                onChange={ev => {
                  const value = ev.target.value as Array<
                    'LOAD' | 'DEMAND' | 'REGULATED_DEMAND'
                  >;

                  if (value.length === 0) {
                    return;
                  }

                  setDataTypes(value);
                }}
              >
                <MenuItem value="LOAD">
                  <ListItemIcon>
                    <LabelIcon
                      className={
                        dataTypes.includes('LOAD')
                          ? classes.load
                          : classes.unselected
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="LOAD" />
                </MenuItem>
                <MenuItem value="DEMAND">
                  <ListItemIcon>
                    <LabelIcon
                      className={
                        dataTypes.includes('DEMAND')
                          ? classes.demand
                          : classes.unselected
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="DEMAND" />
                </MenuItem>
                <MenuItem value="REGULATED_DEMAND">
                  <ListItemIcon>
                    <LabelIcon
                      className={
                        dataTypes.includes('REGULATED_DEMAND')
                          ? classes.regulatedDemand
                          : classes.unselected
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="REGULATED DEMAND" />
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Box>
      <SizeMe>
        {({ size }) => (
          <VictoryChart
            height={size.height || 550}
            width={size.width || 350}
            theme={VictoryTheme.material}
          >
            <VictoryAxis
              tickFormat={t => {
                return moment.utc(t).format('HH:mm');
              }}
              tickLabelComponent={
                <VictoryLabel
                  textAnchor="end"
                  verticalAnchor="middle"
                  angle={-90}
                />
              }
            />
            <VictoryAxis dependentAxis />
            <VictoryGroup
              animate={{
                duration: 300,
                onLoad: {
                  duration: 0,
                },
              }}
              offset={
                (size.width || 350) /
                (2 * (visibleBars + 2) * transformedBaseData.length)
              }
              colorScale={colorScale}
            >
              {[
                'LOAD' as 'LOAD',
                'DEMAND' as 'DEMAND',
                'REGULATED_DEMAND' as 'REGULATED_DEMAND',
              ].map(dataType => {
                if (!dataTypes.includes(dataType)) {
                  return null;
                }

                return (
                  <VictoryBar
                    key={`base-${dataType}`}
                    data={transformedBaseData}
                    alignment="middle"
                    x={d => d.x}
                    y={dataTypes.includes(dataType) ? d => d[dataType] : d => 0}
                    style={{
                      data: {
                        opacity: loading
                          ? 0.2
                          : !dataTypes.includes(dataType)
                          ? 0
                          : 1,
                      },
                    }}
                  />
                );
              })}
              {transformedCompareData &&
                [
                  'LOAD' as 'LOAD',
                  'DEMAND' as 'DEMAND',
                  'REGULATED_DEMAND' as 'REGULATED_DEMAND',
                ].map(dataType => {
                  if (!dataTypes.includes(dataType)) {
                    return null;
                  }

                  return (
                    <VictoryBar
                      key={`compare-${dataType}`}
                      data={transformedCompareData}
                      alignment="middle"
                      x={d => d.x}
                      y={
                        dataTypes.includes(dataType) ? d => d[dataType] : d => 0
                      }
                      style={{
                        data: {
                          opacity: loading
                            ? 0.2
                            : !dataTypes.includes(dataType)
                            ? 0
                            : 1,
                        },
                      }}
                    />
                  );
                })}
            </VictoryGroup>
          </VictoryChart>
        )}
      </SizeMe>
    </React.Fragment>
  );
};

export default Graph;
