import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';
import md5 from 'md5';
import React from 'react';
import { useAuthentication } from '../../Authentication';
import { ButtonLink, Link } from '../../ButtonLink';
import PermissionCard from './PermissionCard';

const useClasses = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    overflowY: 'hidden',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    height: 200,
    width: 200,
    alignSelf: 'center',
    border: `4px solid ${theme.palette.secondary.light}`,
  },
  dimmed: {
    opacity: 0.7,
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  spacedButton: {
    marginBottom: theme.spacing(1),
  },
  newAccountBanner: {
    backgroundColor: theme.palette.background.paper,
    borderLeft: `4px solid ${theme.palette.primary.light}`,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const Profile: React.FC<{}> = props => {
  const authState = useAuthentication();
  const classes = useClasses();

  const { authData: currentUser } = authState.authState;

  return (
    <Container className={classes.container} fixed maxWidth="xs">
      <Box display="flex" flexDirection="column" marginTop={8} margin={1}>
        <Avatar
          className={classes.avatar}
          alt={currentUser.attributes.email}
          src={`https://www.gravatar.com/avatar/${md5(
            currentUser.attributes.email,
          )}?d=identicon&s=250`}
        />
        <Typography align="center" color="textSecondary" variant="caption">
          Image generated with{' '}
          <a
            href="https://gravatar.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            Gravatar
          </a>
        </Typography>
        <Typography
          className={classes.marginTop}
          align="center"
          component="h1"
          variant="h4"
        >
          {currentUser.attributes.name}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" align="center">
          {currentUser.attributes.email}
        </Typography>
        <Box marginBottom={4} />
        <ButtonLink
          className={classes.spacedButton}
          fullWidth
          variant="outlined"
          color="primary"
          to="/profile/edit"
        >
          Edit profile
        </ButtonLink>
        <ButtonLink
          className={classes.spacedButton}
          fullWidth
          variant="outlined"
          color="primary"
          to="/profile/change-password"
        >
          Change password
        </ButtonLink>
        <Button
          className={classes.spacedButton}
          color="secondary"
          variant="outlined"
          onClick={() => authState.refresh()}
        >
          Refresh session
        </Button>
      </Box>
      <Divider />
      <Box margin={1}>
        <Typography variant="h5">My permissions</Typography>
        <Typography className={classes.newAccountBanner} variant="body1">
          New accounts are not allowed to do anything beside editing their
          profile. Permissions must be granted manually by an administrator.
          <br />
          <Link to="/contact-us">Feel free to request access here.</Link>
        </Typography>
      </Box>

      <Box margin={1}>
        <PermissionCard
          authorized={true}
          title="Base user"
          subheader="Basic profile access and management"
          items={['Edit your information', 'Change your email']}
        />
      </Box>
      <Box margin={1}>
        <PermissionCard
          authorized={
            !!authState.authState.groups &&
            authState.authState.groups.includes('ArchiveExplorers')
          }
          title="Load archive explorer"
          items={['Explore DSNA archives', 'Visualize entry counts for any TV']}
        />
      </Box>
      <Box margin={1}>
        <PermissionCard
          authorized={
            !!authState.authState.groups &&
            authState.authState.groups.includes('ECDMPortalAdmins')
          }
          title="E-CDM portal manager"
          items={[
            'Manage E-CDM portal users',
            'Grant or revoke permissions on the portal',
          ]}
        />
      </Box>

      <Typography
        variant="caption"
        align="center"
        color="textSecondary"
        gutterBottom
      >
        If you believe this list is not accurate, use the{' '}
        <em>Refresh session</em> button above.
      </Typography>
    </Container>
  );
};

export default Profile;
