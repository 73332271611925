import React from 'react';
import UserGroups from './UserGroups';
import { Typography, makeStyles } from '@material-ui/core';
import { useCognitoUser } from './hooks/useCognitoUser';
import Loader from '../Loader';

const useClasses = makeStyles({
  email: {
    wordWrap: 'break-word',
  },
});

const User: React.FC<{ username: null | undefined | string }> = ({
  username,
}) => {
  const user = useCognitoUser({ username });
  const classes = useClasses();

  if (user.loading) {
    return <Loader />;
  }

  if (user.error || !user.response) {
    return (
      <>
        <Typography variant="h3">An error happened</Typography>
        <Typography variant="body1">
          {user.error ? user.error.message : 'User could not be found'}
        </Typography>
      </>
    );
  }

  const email = (user.response.UserAttributes || []).find(
    ({ Name }) => Name === 'email',
  );

  return (
    <React.Fragment>
      <Typography
        className={classes.email}
        variant="h3"
        component="h1"
        gutterBottom
      >
        {email && email.Value}
      </Typography>
      <UserGroups username={username} />
    </React.Fragment>
  );
};

export default User;
