import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  withMobileDialog,
} from '@material-ui/core';
import CheckedIcon from '@material-ui/icons/CheckBox';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ButtonLink, Link } from '../ButtonLink';
import Loader from '../Loader';
import { pathPrefix } from './constants';
import { useCognitoUsers } from './hooks/useCognitoUsers';
import User from './User';

const ResponsiveDialog = withMobileDialog()(Dialog);

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: theme.spacing(2),
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const Users: React.FC<{ username?: string | null }> = ({ username }) => {
  const [paginationToken, setPaginationToken] = useState<undefined | string>(
    undefined,
  );
  const [searchInput, setSearchInput] = useState<string>('');
  const [filter, setFilter] = useState<string | undefined>(undefined);

  const users = useCognitoUsers({ limit: 15, paginationToken, filter });
  const classes = useStyles();

  if (users.loading && !users.response) {
    return <Loader />;
  }

  if (users.error) {
    return (
      <React.Fragment>
        <Typography className={classes.header} variant="h4" gutterBottom>
          An error happened!
        </Typography>
        <Typography variant="body1" paragraph>
          {users.error.message}
        </Typography>
      </React.Fragment>
    );
  }

  return (
    <>
      <Typography variant="h4" className={classes.header} gutterBottom>
        Users
      </Typography>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          <TextField
            id="search"
            name="search"
            InputProps={{ autoComplete: 'off' }}
            value={searchInput}
            onChange={ev => {
              setSearchInput(ev.target.value);
            }}
          />
          <Button
            onClick={() => {
              if (!searchInput) {
                return;
              }

              // Build a cognito filter
              const filter = `email ^= "${searchInput}"`;
              setFilter(filter);
              setPaginationToken(undefined);
            }}
          >
            Search
          </Button>
          <Button
            disabled={!filter}
            onClick={() => {
              setSearchInput('');
              setFilter(undefined);
              setPaginationToken(undefined);
            }}
          >
            Clear
          </Button>
        </Box>
        <Paper className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Email verified</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.users.map(
                ({
                  Username,
                  Attributes,
                  Enabled,
                  UserCreateDate,
                  UserLastModifiedDate,
                }: any) => {
                  const email = Attributes.find(
                    (attr: any) => attr.Name === 'email',
                  ).Value;
                  const emailVerified = Attributes.find(
                    (attr: any) => attr.Name === 'email_verified',
                  ).Value;
                  const name = Attributes.find(
                    (attr: any) => attr.Name === 'name',
                  ).Value;

                  return (
                    <TableRow key={Username}>
                      <TableCell>
                        <Link to={`${pathPrefix}/users/${Username}`}>
                          {email}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {emailVerified ? (
                          <CheckedIcon color="primary" />
                        ) : (
                          <UncheckedIcon />
                        )}
                      </TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        {Enabled ? (
                          <CheckedIcon color="primary" />
                        ) : (
                          <UncheckedIcon />
                        )}
                      </TableCell>
                      <TableCell>{UserCreateDate.toISOString()}</TableCell>
                      <TableCell>
                        {UserLastModifiedDate.toISOString()}
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </Paper>
        <Button
          variant="outlined"
          disabled={
            users.loading ||
            !(
              users.response &&
              users.response.PaginationToken &&
              users.users.length
            )
          }
          onClick={() =>
            setPaginationToken(
              (users.response && users.response.PaginationToken) || undefined,
            )
          }
        >
          Load more ...
        </Button>
      </Box>
      <ResponsiveDialog fullWidth maxWidth="lg" open={!!username}>
        <DialogTitle>User</DialogTitle>
        <Divider />
        <DialogContent>
          <User username={username} />
        </DialogContent>
        <Divider />
        <DialogActions>
          <ButtonLink to={`${pathPrefix}/users`}>Close</ButtonLink>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
};

export default Users;
