import { useCognito } from './useCognito';
import { useEffect, useReducer } from 'react';
import { Auth as authConstants } from '../../constants';
import { ListUsersResponse } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import produce from 'immer';

type AWSError = import('aws-sdk').AWSError;

type Input = {
  paginationToken?: string;
  limit?: number;
  filter?: string;
};

type State = {
  loading: boolean;
  error: Error | AWSError | null;
  users: NonNullable<ListUsersResponse['Users']>;
  response: ListUsersResponse | null;
};

type Action =
  | {
      type: 'response';
      response: ListUsersResponse;
    }
  | { type: 'error'; error: AWSError | Error }
  | { type: 'loading' }
  | { type: 'clear' };

function reducer(state: State, action: Action): State {
  return produce(state, draft => {
    switch (action.type) {
      case 'loading':
        draft.error = null;
        draft.loading = true;
        return;
      case 'error': {
        draft.loading = false;
        draft.error = action.error;
        draft.users = [];
        draft.response = null;
        return;
      }
      case 'response': {
        if (action.response.Users) {
          const knownUsers = (state.users || []).map(
            ({ Username }) => Username,
          );
          draft.users = [
            ...state.users,
            ...action.response.Users.filter(
              ({ Username }) => !knownUsers.includes(Username),
            ),
          ];
        }

        draft.loading = false;
        draft.error = null;
        draft.response = action.response;
        return;
      }
      case 'clear': {
        draft.loading = false;
        draft.error = null;
        draft.users = [];
        draft.response = null;
      }
    }
  });
}

export function useCognitoUsers({
  paginationToken,
  limit = 30,
  filter,
}: Input): State {
  const cognito = useCognito();
  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    error: null,
    users: [],
    response: null,
  });

  // When filter changes, clear the cache
  useEffect(() => {
    dispatch({ type: 'clear' });
  }, [filter, dispatch]);

  useEffect(() => {
    let isMounted = true;
    const unmount = () => {
      isMounted = false;
    };
    if (!cognito.client) {
      return unmount;
    }

    dispatch({ type: 'loading' });

    cognito.client
      .listUsers({
        UserPoolId: authConstants.userPoolId,
        Limit: limit,
        PaginationToken: paginationToken,
        Filter: filter,
      })
      .promise()
      .then(response => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'response', response });
      })
      .catch(error => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'error', error });
      });

    return unmount;
  }, [dispatch, cognito.client, paginationToken, limit, filter]);

  if (cognito.loading) {
    return {
      ...state,
      loading: true,
    };
  }

  if (cognito.error) {
    return {
      ...state,
      error: cognito.error,
    };
  }

  return state;
}
