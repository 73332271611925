import React from 'react';
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  makeStyles,
  Theme,
} from '@material-ui/core';
import BugReportIcon from '@material-ui/icons/BugReportTwoTone';
import LockIcon from '@material-ui/icons/LockOpenTwoTone';
import HelpIcon from '@material-ui/icons/HelpTwoTone';

const useClasses = makeStyles((theme: Theme) => ({
  icon: {
    width: 'auto',
    height: 'auto',
    maxWidth: 250,
    margin: `${theme.spacing(2)}px auto`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ContactUs = () => {
  const classes = useClasses();

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" margin={1}>
        <Typography variant="h3" gutterBottom>
          Contact us
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" padding={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" component="h3" gutterBottom>
                  Found a bug ?
                </Typography>
                <BugReportIcon className={classes.icon} color="primary" />
                <Typography variant="subtitle1" align="center" paragraph>
                  This website is quite new ! Please report it to help us
                  improve your experience.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  component="a"
                  href="https://gitlab.asap.dsna.fr/postopsnational/ecdm-portal/issues/new?issue[title]=Bug%20report&issue[description]=I%20found%20a%20bug"
                  target="_blank"
                  rel="noopener nofollow"
                >
                  Report a bug
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" component="h3" gutterBottom>
                  Request access ?
                </Typography>
                <LockIcon className={classes.icon} color="primary" />
                <Typography variant="subtitle1" align="center" paragraph>
                  New users of this website just have access to their user
                  profile. Further access must be approved by administrators and
                  can be requested by opening a ticket.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  component="a"
                  href="https://gitlab.asap.dsna.fr/postopsnational/ecdm-portal/issues/new?issue[title]=Access%20request&issue[description]=I%20need%20access%20to%3A"
                  target="_blank"
                  rel="noopener nofollow"
                >
                  Request access
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" component="h3" gutterBottom>
                  Question ? Suggestion ?
                </Typography>
                <HelpIcon className={classes.icon} color="primary" />
                <Typography variant="subtitle1" align="center" paragraph>
                  Your feedback is very valuable to us.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  component="a"
                  href="https://gitlab.asap.dsna.fr/postopsnational/ecdm-portal/issues/new?issue[title]=Feedback"
                  target="_blank"
                  rel="noopener nofollow"
                >
                  Provide feedback
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default ContactUs;
