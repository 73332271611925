import {
  AppBar,
  Avatar,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Toolbar,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import md5 from 'md5';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthentication } from './Authentication';
import { ButtonLink } from './ButtonLink';
import { LeftNavigation } from './Navigation';
import { useHistory } from 'react-router';

const useClasses = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: 10,
  },
  menuIcon: {
    marginRight: theme.spacing(2),
  },
  headerLink: {
    color: theme.palette.primary.contrastText,
    ...theme.typography.h6,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    margin: theme.spacing(1, 0),
  },
  username: {
    fontWeight: 'bold',
    margin: theme.spacing(1),
  },
}));

const ButtonAppBar: React.FC<{}> = () => {
  const history = useHistory();
  const classes = useClasses();
  const { authState } = useAuthentication();
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const { authData: currentUser } = authState;

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor="left"
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
      >
        <LeftNavigation onNavigation={() => setMenuOpen(false)} />
      </SwipeableDrawer>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.menuIcon}
            color="inherit"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/" className={classes.headerLink} component={RouterLink}>
            E-CDM Portal
          </Link>
          <Box flexGrow={1} />
          {authState.authState === 'signedOut' && (
            <ButtonLink to="/signin" color="inherit">
              Login
            </ButtonLink>
          )}
          {authState.authState === 'signedIn' && (
            <>
              <Avatar
                aria-controls="simple-menu"
                className={classes.avatar}
                alt={currentUser.attributes.email}
                src={`https://www.gravatar.com/avatar/${md5(
                  currentUser.attributes.email,
                )}?d=identicon`}
                onClick={ev => {
                  setAnchorEl(ev.currentTarget);
                }}
              />
              <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                keepMounted
                onClose={() => setAnchorEl(null)}
              >
                <Typography className={classes.username}>
                  {currentUser.attributes.name}
                </Typography>
                <Divider />
                <MenuItem
                  onClick={() => {
                    history.push({ pathname: '/profile' });
                    setAnchorEl(null);
                  }}
                >
                  My profile
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    history.push({ pathname: '/signout' });
                    setAnchorEl(null);
                  }}
                >
                  Sign out
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default ButtonAppBar;
