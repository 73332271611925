import { Button, Link, TextField, Typography } from '@material-ui/core';
import Auth from '@aws-amplify/auth';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Done, Layout, useStyles } from './Layout';

const PasswordReset: React.FC<{
  email?: string;
}> = props => {
  const classes = useStyles();
  const [passwordReset, setPasswordReset] = useState<null | string>(null);

  if (passwordReset) {
    return (
      <Layout title="Password reset">
        <Done>
          <Typography align="justify" variant="body2">
            Your password has been reset.{' '}
            <Link
              to={{
                pathname: '/signin',
                search: `?email=${passwordReset}`,
              }}
              component={RouterLink}
            >
              Please sign in using the sign in page.
            </Link>
          </Typography>
        </Done>
      </Layout>
    );
  }

  return (
    <Layout title="Password reset">
      <Formik
        initialValues={{ email: props.email || '', code: '', password: '' }}
        initialStatus={null as null | Error}
        onSubmit={async (
          values,
          { setSubmitting, setFieldError, setStatus },
        ) => {
          setSubmitting(true);
          setStatus(null);
          try {
            await Auth.forgotPasswordSubmit(
              values.email.toLowerCase(),
              values.code,
              values.password,
            );
            setSubmitting(false);
            setPasswordReset(values.email.toLowerCase());
          } catch (err) {
            if (err.code === 'CodeMismatchException') {
              setFieldError('code', 'Invalid code submitted');
            } else {
              // Unhandled error, show it raw to the user
              setStatus(err);
            }

            setSubmitting(false);
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('A valid email is required')
            .required('Required'),
          code: Yup.string().required('Required'),
          password: Yup.string()
            .min(8, '8 characters minimum')
            .required('Required'),
        })}
      >
        {formikProps => {
          const {
            handleSubmit,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            status,
          } = formikProps;

          return (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                disabled={!!props.email}
                autoComplete="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={!!status || !!(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="code"
                label="Code"
                id="code"
                autoComplete="new-password"
                error={!!errors.code}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.code}
                helperText={errors.code}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                error={!!errors.password}
                onChange={handleChange}
                disabled={isSubmitting}
                onBlur={handleBlur}
                value={values.password}
                helperText={errors.password}
              />

              {status && (
                <Typography className={classes.globalError} variant="body2">
                  {status.message}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                className={classes.submitButton}
              >
                Reset password
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default PasswordReset;
