import produce from 'immer';
type AWSError = import('aws-sdk').AWSError;

export type State<T> = {
  loading: boolean;
  error: Error | AWSError | null;
  response: T | null;
};

export type Action<T> =
  | {
      type: 'response';
      response: T;
    }
  | { type: 'error'; error: AWSError | Error }
  | { type: 'loading' };

export function createReducer<T>() {
  return function reducer(state: State<T>, action: Action<T>): State<T> {
    return produce(state, draft => {
      switch (action.type) {
        case 'loading':
          draft.error = null;
          draft.loading = true;
          return;
        case 'error': {
          draft.loading = false;
          draft.error = action.error;
          draft.response = null;
          return;
        }
        case 'response': {
          draft.loading = false;
          draft.error = null;
          draft.response = action.response as any;
          return;
        }
      }
    });
  };
}
