import {
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Auth from '@aws-amplify/auth';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Layout, { useStyles } from './Layout';

const SignOut: React.FC<{}> = () => {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [allDevices, setAllDevices] = useState<boolean>(false);

  return (
    <Layout title="Sign out">
      <Typography>Are you sure ?</Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={allDevices}
            onChange={ev => {
              const checked = ev.target.checked;
              setAllDevices(checked);
            }}
          />
        }
        label="Sign out from all devices"
      />
      <div className={classes.actionsContainer}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.submitButton}
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            await Auth.signOut({ global: allDevices });
            history.push('/');
          }}
        >
          Confirm
        </Button>
        <Button
          variant="outlined"
          color="default"
          fullWidth
          className={classes.submitButton}
          disabled={loading}
          onClick={() => {
            history.goBack();
          }}
        >
          Cancel
        </Button>
      </div>
    </Layout>
  );
};

export default SignOut;
