import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import React, { useCallback, useContext, useEffect, useReducer } from 'react';

type AuthState =
  | {
      authState: 'loading';
      authData: null | AuthData;
      authError: null;
      groups: null | Array<string>;
    }
  | {
      authState: 'signedIn';
      authData: AuthData;
      authError: null;
      groups: Array<string>;
    }
  | {
      authState: 'signedOut';
      authData: null;
      authError: null;
      groups: null;
    }
  | {
      authState: 'error';
      authData: null | AuthData;
      authError: Error;
      groups: null | Array<string>;
    };

type AuthData = any;

type AuthAction =
  | { type: 'loading' }
  | { type: 'signedIn'; data: AuthData }
  | { type: 'signedOut' }
  | { type: 'error'; error: Error };

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'signedIn':
      const groups =
        (action.data &&
          action.data.signInUserSession &&
          action.data.signInUserSession.accessToken &&
          action.data.signInUserSession.accessToken.payload &&
          action.data.signInUserSession.accessToken.payload[
            'cognito:groups'
          ]) ||
        [];

      return {
        authState: 'signedIn',
        authData: action.data,
        authError: null,
        groups,
      };
    case 'signedOut':
      return {
        authState: 'signedOut',
        authData: null,
        authError: null,
        groups: null,
      };
    case 'loading': {
      return {
        ...state,
        authError: null,
        authState: 'loading',
      };
    }
    case 'error': {
      return {
        ...state,
        authState: 'error',
        authError: action.error,
      };
    }
  }
}

async function getCurrentUser() {
  return Auth.currentAuthenticatedUser({ bypassCache: true });
}

type AuthContext = {
  authState: AuthState;
  dispatch: (action: AuthAction) => void;
  refresh: () => Promise<null | AuthData>;
};

const Context = React.createContext<AuthContext>({
  authState: {
    authState: 'loading',
    authData: null,
    authError: null,
    groups: null,
  },
  dispatch: () => {},
  refresh: async () => {
    return null;
  },
});

const Authentication: React.FC<{ children?: any }> = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, {
    authState: 'loading',
    authData: null,
    authError: null,
    groups: null,
  });

  // Try to load credentials from localstorage
  useEffect(() => {
    getCurrentUser()
      .then(async user => {
        if (user && 'attributes' in user) {
          dispatch({ type: 'signedIn', data: user });
        } else {
          await Auth.signOut();
          dispatch({ type: 'signedOut' });
        }
      })
      .catch(async err => {
        await Auth.signOut();

        // if (err === 'not authenticated') {
        //   dispatch({ type: 'signedOut' });
        // }

        dispatch({ type: 'signedOut' });
      });
  }, []);

  // Listen for sign in events
  useEffect(() => {
    function listener(data: any) {
      console.warn('Hub event', data);
      switch (data.payload.event) {
        case 'signIn': {
          Auth.currentAuthenticatedUser().then(user => {
            dispatch({ type: 'signedIn', data: user });
          });
          return;
        }
        case 'signOut': {
          dispatch({ type: 'signedOut' });
          return;
        }
      }
    }

    Hub.listen('auth', listener);

    return () => Hub.remove('auth', listener);
  }, [dispatch]);

  const refresh = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      if (currentUser && 'attributes' in currentUser) {
        dispatch({ type: 'signedIn', data: currentUser });
      } else {
        await Auth.signOut();
        dispatch({ type: 'signedOut' });
      }
    } catch (err) {}
  }, [dispatch]);

  return (
    <Context.Provider value={{ authState, dispatch, refresh }}>
      {children || null}
    </Context.Provider>
  );
};

export function useAuthentication(): AuthContext {
  const ctx = useContext(Context);

  return ctx;
}

export default Authentication;
