import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Typography, LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  progress: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const SoftRedirect: React.FC<{
  title: string;
  redirect?: React.ComponentProps<typeof Redirect>['to'];
  redirectIn?: number;
  children?: React.ReactNode;
}> = ({ title, redirect, redirectIn = 5000, children }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (!redirect) {
      return;
    }

    const redirectTime = new Date().valueOf() + redirectIn;
    // On mount, if we have a redirect prop
    const interval = setInterval(() => {
      const now = new Date().valueOf();

      const progress = 100 * (1 - (redirectTime - now) / redirectIn);

      setProgress(progress);
    }, 300);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [redirect, redirectIn]);

  if (redirect && progress > 100) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <Typography
        className={classes.title}
        gutterBottom
        align="center"
        variant="h2"
      >
        {title}
      </Typography>
      {!!redirect && (
        <LinearProgress
          className={classes.progress}
          variant="determinate"
          value={progress}
        />
      )}
      {children}
    </>
  );
};

export default SoftRedirect;
