import 'core-js/features/array/flat';
import 'core-js/features/array/flat-map';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import App from './App';
import './auth-config';
import Authentication from './Authentication';
import './global.css';
import ScrollToTop from './ScrollToTop';
import ThemeProvider from './ThemeProvider';

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <ThemeProvider>
      <Router>
        <ScrollToTop>
          <Authentication>
            <App />
          </Authentication>
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root'),
);
