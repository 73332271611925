export type LinkNavigationItem = {
  type: 'LinkNavigationItem';
  title: string;
  link: string;
};

export type GroupNavigationItem = {
  type: 'GroupNavigationItem';
  title: string;
  cognitoGroupMembership?: string;
  items: Array<LinkNavigationItem>;
};

export type NavigationItem = LinkNavigationItem | GroupNavigationItem;

export const navigation: Array<NavigationItem> = [
  {
    type: 'GroupNavigationItem',
    title: 'Load archives',
    cognitoGroupMembership: 'ArchiveExplorers',
    items: [
      { type: 'LinkNavigationItem', title: 'Browse', link: '/archive' },
      { type: 'LinkNavigationItem', title: 'Status', link: '/archive/status' },
    ],
  },
  {
    type: 'GroupNavigationItem',
    title: 'Control room archives',
    cognitoGroupMembership: 'ArchiveExplorers',
    items: [
      {
        type: 'LinkNavigationItem',
        title: 'Browse',
        link: '/control-room-archive',
      },
    ],
  },
  {
    type: 'GroupNavigationItem',
    title: 'Portal administration',
    cognitoGroupMembership: 'ECDMPortalAdmins',
    items: [
      { type: 'LinkNavigationItem', title: 'Dashboard', link: '/portal' },
      { type: 'LinkNavigationItem', title: 'Users', link: '/portal/users' },
    ],
  },
  {
    type: 'GroupNavigationItem',
    title: 'About',
    items: [
      {
        type: 'LinkNavigationItem',
        title: 'E-CDM Portal',
        link: '/about/ecdm-portal',
      },
      {
        type: 'LinkNavigationItem',
        title: 'Traffic load archiver',
        link: '/about/archive-explorer',
      },
      {
        type: 'LinkNavigationItem',
        title: 'B2B Proxy',
        link: '/about/b2b-proxy',
      },
      {
        type: 'LinkNavigationItem',
        title: 'Contact us',
        link: '/contact-us',
      },
    ],
  },
];

export const signedOut: Array<LinkNavigationItem> = [
  {
    type: 'LinkNavigationItem',
    title: 'Sign in',
    link: '/signin',
  },
  {
    type: 'LinkNavigationItem',
    title: 'Sign up',
    link: '/signup',
  },
];

export const signedIn: Array<LinkNavigationItem> = [
  {
    type: 'LinkNavigationItem',
    title: 'My profile',
    link: '/profile',
  },
  {
    type: 'LinkNavigationItem',
    title: 'Sign out',
    link: '/signout',
  },
];
