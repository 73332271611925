import { Button, Grid, Link, TextField, Typography } from '@material-ui/core';
import Auth from '@aws-amplify/auth';
import { Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Layout, useStyles } from './Layout';

const SignIn: React.FC<{}> = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      initialStatus={null as null | Error}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting, setFieldError, setStatus }) => {
        setSubmitting(true);
        setStatus(null);
        try {
          // https://aws-amplify.github.io/docs/js/authentication#sign-in
          await Auth.signIn(values.email.toLowerCase(), values.password);
          setSubmitting(false);
        } catch (err) {
          if (err.code === 'UserNotFoundException') {
            setFieldError('email', 'User not found');
          } else if (err.code === 'NotAuthorizedException') {
            setFieldError('password', 'Invalid password');
          } else if (err.code === 'UserNotConfirmedException') {
            history.push({
              pathname: '/signup/confirm',
              search: `?email=${values.email.toLowerCase()}`,
            });
            return;
          } else {
            // Unhandled error, show it row to the user
            setStatus(err);
          }

          setSubmitting(false);
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('A valid email is required')
          .required('Required'),
        password: Yup.string().required('Required'),
      })}
    >
      {props => {
        const {
          handleSubmit,
          submitForm,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          dirty,
          isSubmitting,
          isValid,
          status,
        } = props;

        return (
          <Layout title="Sign in">
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={!!(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!!(touched.password && errors.password)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                helperText={touched.password && errors.password}
              />
              {status && (
                <Typography className={classes.globalError} variant="body2">
                  {status.message}
                </Typography>
              )}
              <div className={classes.actionsContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!dirty || isSubmitting || !isValid}
                  onClick={() => {
                    submitForm();
                  }}
                  className={classes.submitButton}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      component={RouterLink}
                      to="/signin/forgot-password"
                      variant="body2"
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/signup" variant="body2">
                      Don't have an account? Sign Up
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Layout>
        );
      }}
    </Formik>
  );
};

export default SignIn;
