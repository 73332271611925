import { ICredentials } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import S3 from 'aws-sdk/clients/s3';
import { useEffect, useReducer, useState } from 'react';
import { REGION } from '../ArchiveExplorer/constants';

export type AWSError = import('aws-sdk').AWSError;

type State = {
  loading: boolean;
  error: null | AWSError | Error;
  client: null | S3;
};

type Action =
  | {
      type: 'client';
      client: S3;
    }
  | { type: 'error'; error: AWSError | Error }
  | { type: 'loading' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: true,
      };
    case 'client':
      return {
        loading: false,
        error: null,
        client: action.client,
      };
    case 'error':
      return {
        loading: false,
        error: action.error,
        client: null,
      };
  }
}

async function getClient(credentials: ICredentials) {
  const client = new S3({
    region: REGION,
    credentials: Auth.essentialCredentials(credentials),
  });

  return client;
}

export function useS3() {
  const [credentials, setCredentials] = useState<null | ICredentials>(null);

  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    error: null,
    client: null,
  });

  useEffect(() => {
    let isMounted = true;

    Auth.currentCredentials()
      .then(credentials => {
        if (!isMounted) {
          return;
        }

        if (credentials instanceof Error) {
          throw credentials;
        }

        setCredentials(credentials);
      })
      .catch(error => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'error', error });
      });

    return () => {
      isMounted = false;
    };
  }, [setCredentials, dispatch]);

  useEffect(() => {
    let isMounted = true;
    const unmount = () => {
      isMounted = false;
    };

    if (!credentials) {
      return unmount;
    }

    getClient(credentials)
      .then(client => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'client', client });
      })
      .catch(error => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'error', error });
      });

    return unmount;
  }, [credentials, dispatch]);

  return state;
}
