import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Center from './Center';

const Loader: React.FC<{}> = () => (
  <Center>
    <CircularProgress />
  </Center>
);

export default Loader;
