import { useEffect, useReducer, useMemo } from 'react';
import { AWSError, useS3 } from '../hooks/useS3';
import { BUCKET, BUCKET_PREFIX } from './constants';
import moment from 'moment';

type State = {
  loading: boolean;
  error: null | AWSError | Error;
  content: null | object;
};

type Action =
  | {
      type: 'start';
    }
  | { type: 'error'; error: AWSError | Error }
  | { type: 'end'; content: null | object }
  | { type: 'clear' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'start':
      return {
        ...state,
        error: null,
        loading: true,
      };
    case 'error':
      return {
        ...state,
        content: null,
        error: action.error,
        loading: false,
      };
    case 'end': {
      return {
        ...state,
        error: null,
        loading: false,
        content: action.content,
      };
    }
    case 'clear': {
      return {
        error: null,
        loading: false,
        content: null,
      };
    }
  }
}

type HookOptions = {
  center: string;
  trafficVolume: string;
  when: null | Date;
};

export function useGraphData({
  center,
  trafficVolume,
  when,
}: HookOptions): [State] {
  const s3 = useS3();
  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    content: null,
    error: null,
  });

  const whenISO = when?.toISOString();

  const Key = useMemo(() => {
    if (!whenISO || !trafficVolume || !center) {
      return null;
    }

    return `${BUCKET_PREFIX}/${center}/${moment
      .utc(whenISO)
      .format('YYYY/MM/DD')}/${trafficVolume}/${moment
      .utc(whenISO)
      .toISOString()}`;
  }, [whenISO, center, trafficVolume]);

  const r = useMemo(
    () => ({
      ...state,
      loading: state.loading || s3.loading,
      error: state.error || s3.error || null,
    }),
    [s3.loading, s3.error, state],
  );

  useEffect(() => {
    let isMounted = true;
    if (!s3.client || !Key) {
      dispatch({ type: 'clear' });
      return;
    }

    dispatch({ type: 'start' });
    s3.client
      .getObject({
        Bucket: BUCKET,
        Key,
      })
      .promise()
      .then(res => {
        if (!isMounted) {
          return;
        }

        dispatch({
          type: 'end',
          content: res.Body ? JSON.parse(res.Body.toString()) : null,
        });
      })
      .catch(error => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'error', error });
      });

    return () => {
      isMounted = false;
    };
  }, [Key, s3.client]);

  return [r] as [typeof r];
}
