import React from 'react';
import { Typography, Box, Link, makeStyles, Theme } from '@material-ui/core';
import ecdmPortalImage from './ECDMPortal.png';

const useClasses = makeStyles((theme: Theme) => ({
  link: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  image: {
    maxWidth: '100%',
    width: 600,
  },
}));

const ECDMPortal = () => {
  const classes = useClasses();

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" margin={1}>
        <Typography variant="h3" gutterBottom>
          E-CDM Portal
        </Typography>
        <Typography variant="caption" align="center" gutterBottom>
          <Link
            className={classes.link}
            href={ecdmPortalImage}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.image}
              src={ecdmPortalImage}
              alt="E-CDM Portal architecture"
            />
          </Link>
          <br />
          E-CDM Portal architecture
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default ECDMPortal;
