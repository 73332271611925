import { Avatar, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React from 'react';
import { Redirect } from 'react-router';
import SoftRedirect from '../SoftRedirect';

type Props = {
  title?: React.ReactNode;
  children: React.ReactNode;
};

export const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(1, 0),
  },
  globalError: {
    color: theme.palette.error.main,
  },
  hiddenSubmitButton: {
    position: 'absolute',
    left: -9999,
    width: 1,
    height: 1,
  },
  actionsContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-end',
  },
}));

export const Layout: React.FC<Props> = ({ children, title }) => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        {!!title && (
          <Typography component="h1" variant="h5">
            {title}
          </Typography>
        )}
        {children}
      </div>
    </Container>
  );
};

export const Done: React.FC<{
  redirect?: React.ComponentProps<typeof Redirect>['to'];
  redirectIn?: number;
  children?: React.ReactNode;
}> = ({ redirect, redirectIn, children }) => {
  return (
    <SoftRedirect
      title="Done !"
      redirect={redirect}
      redirectIn={redirectIn}
      children={children}
    />
  );
};

export default Layout;
