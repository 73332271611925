import React from 'react';
import SoftRedirect from '../SoftRedirect';
import Center from '../Center';
import { Typography, Box } from '@material-ui/core';

const Unauthorized: React.FC<{}> = () => {
  return (
    <Center>
      <Box margin={2}>
        <SoftRedirect title="Unauthorized !" redirect="/">
          <Typography align="justify" variant="body2">
            You are not authorized to perform this action. Please contact an
            administrator.
          </Typography>
        </SoftRedirect>
      </Box>
    </Center>
  );
};

export default Unauthorized;
