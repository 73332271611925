import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
}));

function Center({ children }: { children?: React.ReactNode }) {
  const classes = useStyles();

  return <div className={classes.wrapper}>{children}</div>;
}

export default Center;
