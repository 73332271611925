import { Box } from '@material-ui/core';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useAuthentication } from '../Authentication';
import Error404 from '../pages/Error404';
import { pathPrefix } from './constants';
import Dashboard from './Dashboard';

export { pathPrefix } from './constants';

export const Index: React.FC<{}> = () => {
  // Authenticated only route
  const { authState } = useAuthentication();
  if (authState.authState !== 'signedIn') {
    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" flexGrow={1} padding={1}>
        <Switch>
          <Route path={`${pathPrefix}/:id?`} component={Dashboard} />
          <Route component={Error404} />
        </Switch>
      </Box>
    </React.Fragment>
  );
};
