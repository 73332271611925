import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Moment } from 'moment';
import React from 'react';
import Loader from '../Loader';
import { useTrafficVolumes } from './useTrafficVolumes';

const useClasses = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const TrafficVolumePicker: React.FC<{
  center: null | string;
  date: null | Moment;
  trafficVolume: null | string;
  onChange: React.ComponentProps<typeof Select>['onChange'];
}> = ({ center, onChange, date, trafficVolume }) => {
  const classes = useClasses();
  const state = useTrafficVolumes({
    center,
    when: date ? date.toDate() : null,
  });

  if (state.loading && !state.records) {
    return <Loader />;
  }

  if (state.error) {
    if (
      'code' in state.error &&
      state.error.code === 'NotAuthorizedException'
    ) {
      throw state.error;
    }

    return (
      <>
        <Typography gutterBottom variant="h3">
          An error happened
        </Typography>
        <Typography variant="body1" paragraph>
          {state.error.message}
        </Typography>
      </>
    );
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="trafficVolume">TrafficVolume</InputLabel>
      <Select
        value={trafficVolume || ''}
        onChange={onChange}
        inputProps={{
          name: 'trafficVolume',
          id: 'trafficVolume',
        }}
      >
        {state.records &&
          state.records.map(tv => (
            <MenuItem key={tv} value={tv}>
              {tv}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
export default TrafficVolumePicker;
