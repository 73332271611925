import React from 'react';
import SignIn from './Auth/SignIn';
import SignUp from './Auth/SignUp';
import ConfirmEmail from './Auth/ConfirmEmail';
import SignOut from './Auth/SignOut';
import ResendConfirmation from './Auth/ResendConfirmation';
import ForgottenPassword from './Auth/ForgottenPassword';
import PasswordReset from './Auth/PasswordReset';
import ChangePassword from './Auth/ChangePassword';
import { Switch, Route, Redirect } from 'react-router';
import HomePage from './pages/Home';
import Profile from './pages/Profile';
import { useAuthentication } from './Authentication';
import queryString from 'query-string';
import EditProfile from './Auth/EditProfile';
import * as About from './pages/About';
import ContactUs from './pages/ContactUs';

import {
  pathPrefix as portalAdminPrefix,
  Index as PortalAdmin,
} from './Portal';

import {
  pathPrefix as archivePrefix,
  Index as ArchiveExplorer,
} from './ArchiveExplorer';

import {
  pathPrefix as controlRoomPrefix,
  Index as ControlRoomExplorer,
} from './ControlRoomExplorer';

import AuthorizationErrorCatcher from './AuthorizationErrorCatcher';
import Error404 from './pages/Error404';

const Content: React.FC<{}> = () => {
  const { authState } = useAuthentication();

  return (
    <>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route
          path={portalAdminPrefix}
          render={() => {
            return (
              <AuthorizationErrorCatcher>
                <PortalAdmin />
              </AuthorizationErrorCatcher>
            );
          }}
        />
        <Route
          path={archivePrefix}
          render={() => {
            return (
              <AuthorizationErrorCatcher>
                <ArchiveExplorer />
              </AuthorizationErrorCatcher>
            );
          }}
        />
        <Route
          path={controlRoomPrefix}
          render={() => {
            return (
              <AuthorizationErrorCatcher>
                <ControlRoomExplorer />
              </AuthorizationErrorCatcher>
            );
          }}
        />
        <Route
          path={'/signup'}
          exact
          render={() => {
            if (authState.authState === 'signedIn') {
              return <Redirect to="/" />;
            }

            return <SignUp />;
          }}
        />

        <Route
          path="/signup/confirm"
          exact
          render={({ location }) => {
            const params = queryString.parse(location.search);
            return (
              <ConfirmEmail
                email={!!params.email ? `${params.email}` : undefined}
                flow={params.flow === 'update' ? 'update' : 'signup'}
              />
            );
          }}
        />

        <Route
          path="/signup/resend-confirmation"
          exact
          render={({ location }) => {
            if (authState.authState === 'signedIn') {
              return <Redirect to="/" />;
            }
            const params = queryString.parse(location.search);

            return (
              <ResendConfirmation
                email={!!params.email ? `${params.email}` : undefined}
              />
            );
          }}
        />

        <Route
          path="/signin"
          exact
          render={() => {
            if (authState.authState === 'signedIn') {
              return <Redirect to="/" />;
            }

            return <SignIn />;
          }}
        />

        <Route
          path="/signin/forgot-password"
          exact
          render={() => {
            if (authState.authState === 'signedIn') {
              return <Redirect to="/" />;
            }

            return <ForgottenPassword />;
          }}
        />

        <Route
          path="/signin/password-reset"
          exact
          render={({ location }) => {
            if (authState.authState === 'signedIn') {
              return <Redirect to="/" />;
            }

            const params = queryString.parse(location.search);

            return (
              <PasswordReset
                email={!!params.email ? `${params.email}` : undefined}
              />
            );
          }}
        />

        <Route
          path="/profile"
          exact
          render={() => {
            if (authState.authState !== 'signedIn') {
              return <Redirect to="/" />;
            }

            return <Profile />;
          }}
        />

        <Route
          path="/profile/edit"
          exact
          render={() => {
            if (authState.authState !== 'signedIn') {
              return <Redirect to="/" />;
            }

            return <EditProfile />;
          }}
        />
        <Route
          path="/profile/change-password"
          exact
          render={() => {
            if (authState.authState !== 'signedIn') {
              return <Redirect to="/" />;
            }

            return <ChangePassword />;
          }}
        />
        <Route
          path="/signout"
          exact
          render={() => {
            if (authState.authState === 'signedOut') {
              return <Redirect to="/" />;
            }

            return <SignOut />;
          }}
        />
        <Route
          path="/about/archive-explorer"
          component={About.ArchiveExplorer}
        />
        <Route path="/about/b2b-proxy" component={About.B2BProxy} />
        <Route path="/about/ecdm-portal" component={About.ECDMPortal} />
        <Route path="/contact-us" component={ContactUs} />
        <Route component={Error404} />
      </Switch>
    </>
  );
};

export default Content;
