import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Box,
} from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { ButtonLink } from '../ButtonLink';
import CenterPicker from './CenterPicker';
import { pathPrefix } from './constants';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import TrafficVolumePicker from './TrafficVolumePicker';

const Dashboard: React.FC<{}> = () => {
  const [selectedCenter, setSelectedCenter] = useState('');
  const [selectedDate, setSelectedDate] = useState<null | Moment>(null);
  const [selectedTrafficVolume, setSelectedTrafficVolume] = useState<
    null | string
  >(null);

  const [selectedTime, setSelectedTime] = useState<null | Moment>(null);

  function getActiveStep() {
    if (!selectedCenter) {
      return 0;
    }

    if (!selectedDate) {
      return 1;
    }

    if (!selectedTrafficVolume) {
      return 2;
    }

    if (!selectedTime) {
      return 3;
    }

    return 4;
  }

  return (
    <React.Fragment>
      <Box marginBottom={4}>
        <Typography variant="h3">Archive explorer</Typography>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Stepper activeStep={getActiveStep()} orientation="vertical">
                <Step key={'select-center'}>
                  <StepLabel>
                    {selectedCenter
                      ? `Center: ${selectedCenter}`
                      : 'Pick a center'}
                  </StepLabel>
                  <StepContent>
                    <CenterPicker
                      center={selectedCenter}
                      onChange={ev => {
                        const value = ev.target.value as string;
                        setSelectedCenter(value);
                      }}
                    />
                  </StepContent>
                </Step>
                <Step key={'select-date'}>
                  <StepLabel>Pick a date</StepLabel>
                  <StepContent>
                    <Box margin="auto" maxWidth={450}>
                      <DatePicker
                        center={selectedCenter}
                        date={selectedDate || moment.utc()}
                        onChange={date => {
                          setSelectedDate(date as Moment);
                        }}
                      />
                    </Box>
                  </StepContent>
                </Step>
                <Step key="select-tv">
                  <StepLabel>Pick a traffic volume</StepLabel>
                  <StepContent>
                    <TrafficVolumePicker
                      center={selectedCenter}
                      date={selectedDate}
                      trafficVolume={selectedTrafficVolume}
                      onChange={ev => {
                        const trafficVolume = ev.target.value as string;
                        setSelectedTrafficVolume(trafficVolume);
                      }}
                    />
                  </StepContent>
                </Step>
                <Step key="select-time">
                  <StepLabel>Select time</StepLabel>
                  <StepContent>
                    <TimePicker
                      date={
                        selectedTime ||
                        (selectedDate ? selectedDate.startOf('hour') : null)
                      }
                      onChange={date => {
                        setSelectedTime(date);
                      }}
                    />
                  </StepContent>
                </Step>
                <Step key="confirm">
                  <StepLabel>Confirmation</StepLabel>
                  <StepContent>
                    <Typography style={{ fontWeight: 'bold' }} variant="body1">
                      {selectedTrafficVolume} @{' '}
                      {selectedTime && selectedTime.format('YYYY-MM-DD HH:mm')}
                    </Typography>
                    <Typography>Is this correct ?</Typography>
                  </StepContent>
                </Step>
              </Stepper>
            </CardContent>
            <CardActions>
              <Button
                disabled={getActiveStep() === 0}
                onClick={() => {
                  const activeStep = getActiveStep();
                  if (activeStep === 1) {
                    setSelectedCenter('');
                  } else if (activeStep === 2) {
                    setSelectedDate(null);
                  } else if (activeStep === 3) {
                    setSelectedTrafficVolume(null);
                  } else if (activeStep === 4) {
                    setSelectedTime(null);
                  }
                }}
                color="primary"
                variant="contained"
              >
                Back
              </Button>
              <ButtonLink
                disabled={
                  getActiveStep() !== 4 ||
                  !selectedTrafficVolume ||
                  !selectedTime ||
                  !selectedCenter
                }
                color="primary"
                variant="contained"
                to={{
                  pathname: `${pathPrefix}/view`,
                  search:
                    selectedTime && selectedTrafficVolume && selectedCenter
                      ? `?center=${selectedCenter}&trafficVolume=${selectedTrafficVolume}&when=${selectedTime.toISOString()}`
                      : undefined,
                }}
              >
                Confirm
              </ButtonLink>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Dashboard;
