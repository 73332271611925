import React from 'react';
import { Theme, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';

const buildDate = moment.utc(process.env.REACT_APP_BUILD_TIME).toISOString();

const useClasses = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey['A700'],
    color: theme.palette.getContrastText(theme.palette.grey['A700']),
    minHeight: 48,
  },
  footerText: {
    alignSelf: 'center',
    opacity: 0.4,
  },
}));

const Footer: React.FC<{}> = () => {
  const classes = useClasses();

  return (
    <footer className={classes.footer}>
      <Typography className={classes.footerText} variant="caption">
        Copyright © 2019 DGAC / DSNA
      </Typography>
      <Typography className={classes.footerText} variant="caption">
        Version: {process.env.REACT_APP_BUILD_HASH || 'NoVersionDetected'}@
        {buildDate}
      </Typography>
    </footer>
  );
};

export default Footer;
