import React, { useState } from 'react';
import { TimePicker as MuiTimePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';

const TimePicker: React.FC<{
  step?: number;
  date: null | Moment;
  onChange: React.ComponentProps<typeof MuiTimePicker>['onAccept'];
}> = ({ date, onChange, step = 20 }) => {
  const [time, setTime] = useState<null | Moment>(
    date || moment.utc().startOf('hour'),
  );

  return (
    <MuiTimePicker
      ampm={false}
      label="Time"
      value={time}
      onChange={date => {
        setTime(date);
      }}
      onAccept={onChange}
      minutesStep={step}
    />
  );
};

export default TimePicker;
