import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { useAuthentication } from '../Authentication';
import { AdapterNavLink as RouterLink } from '../ButtonLink';
import {
  LinkNavigationItem,
  navigation,
  signedIn,
  signedOut,
} from './constants';

const useClasses = makeStyles((theme: Theme) => ({
  list: {
    minWidth: 250,
  },
  selectedNavLink: {
    backgroundColor: theme.palette.grey[300],
  },
}));

const LeftNavigation: React.FC<{
  onNavigation?: () => void;
}> = ({ onNavigation }) => {
  const classes = useClasses();
  const { authState } = useAuthentication();

  const isSignedIn = authState.authState === 'signedIn';

  return (
    <List className={classes.list}>
      {isSignedIn && <Subnavigation onClick={onNavigation} items={signedIn} />}
      {!isSignedIn && (
        <Subnavigation onClick={onNavigation} items={signedOut} />
      )}
      {isSignedIn &&
        navigation.map((item, idx) => {
          const { title } = item;

          if (item.type === 'GroupNavigationItem') {
            const { items, cognitoGroupMembership } = item;

            // Check that current user has the right group membership
            if (
              cognitoGroupMembership &&
              (!authState.groups ||
                !authState.groups.length ||
                !authState.groups.includes(cognitoGroupMembership))
            ) {
              return null;
            }

            return (
              <React.Fragment key={`group-${title}-${idx}`}>
                <Divider />
                <Subnavigation
                  onClick={onNavigation}
                  title={title}
                  items={items}
                />
              </React.Fragment>
            );
          }

          const { link } = item;

          return (
            <ListItem
              onClick={onNavigation}
              component={RouterLink}
              exact
              to={link}
              key={`${title}-${link}`}
              activeClassName="Mui-selected"
              button
            >
              <ListItemText primary={title} />
            </ListItem>
          );
        })}
    </List>
  );
};

function Subnavigation({
  title,
  items,
  className,
  onClick,
}: {
  className?: string;
  title?: string;
  items: Array<LinkNavigationItem>;
  onClick?: () => any;
}) {
  return (
    <List
      className={className}
      subheader={
        !!title ? (
          <ListSubheader component="div">{title}</ListSubheader>
        ) : (
          undefined
        )
      }
    >
      {items.map(({ title, link }) => (
        <ListItem
          component={RouterLink}
          exact
          to={link}
          onClick={onClick}
          activeClassName="Mui-selected"
          key={`${title}-${link}`}
          button
        >
          <ListItemText primary={title} />
        </ListItem>
      ))}
    </List>
  );
}

export default LeftNavigation;
