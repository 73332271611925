import { DescribeUserPoolResponse } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { useEffect, useReducer } from 'react';
import { Auth as authConstants } from '../../constants';
import { createReducer, State } from './reducer';
import { useCognito } from './useCognito';

const reducer = createReducer<DescribeUserPoolResponse>();

export function useCognitoUserPool(): State<DescribeUserPoolResponse> {
  const cognito = useCognito();
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    error: null,
    response: null,
  });

  useEffect(() => {
    let isMounted = true;
    const unmount = () => {
      isMounted = false;
    };
    if (!cognito.client) {
      return unmount;
    }

    dispatch({ type: 'loading' });

    cognito.client
      .describeUserPool({
        UserPoolId: authConstants.userPoolId,
      })
      .promise()
      .then(response => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'response', response });
      })
      .catch(error => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'error', error });
      });

    return unmount;
  }, [dispatch, cognito.client]);

  if (cognito.loading) {
    return {
      ...state,
      loading: true,
    };
  }

  if (cognito.error) {
    return {
      ...state,
      error: cognito.error,
    };
  }

  return state;
}
