import {
  Button,
  Link,
  SnackbarContent,
  TextField,
  Typography,
  Theme,
  makeStyles,
  Box,
} from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import WarningIcon from '@material-ui/icons/Warning';
import Auth from '@aws-amplify/auth';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuthentication } from '../Authentication';
import { Layout, useStyles } from './Layout';
const warningColor = amber[600];

const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <RouterLink innerRef={ref as any} {...props} />,
);

const useClasses = makeStyles((theme: Theme) => ({
  emailNotVerified: {
    width: '100%',
    backgroundColor: warningColor,
    color: theme.palette.getContrastText(warningColor),
  },
  emailNotVerifiedIcon: {
    marginRight: theme.spacing(2),
    opacity: 0.8,
  },
}));

const EditProfile: React.FC<{
  email?: string;
}> = props => {
  const formClasses = useStyles();
  const classes = useClasses();

  const { authState, refresh } = useAuthentication();
  const { authData: currentUser } = authState;
  const [initialValues] = useState<{
    email: string;
    name: string;
  }>({
    email: currentUser.attributes.email,
    name: currentUser.attributes.name,
  });
  return (
    <Layout title="Edit user profile">
      <Button
        color="primary"
        component={AdapterLink}
        fullWidth
        to="/profile/change-password"
      >
        Change password
      </Button>
      {currentUser.attributes.email_verified === false && (
        <SnackbarContent
          className={classes.emailNotVerified}
          message={
            <Box display="flex" flexDirection="row" alignItems="center">
              <WarningIcon
                className={classes.emailNotVerifiedIcon}
                fontSize="large"
              />
              <span>
                This email has not been verified
                <br />
                <Link
                  component={RouterLink}
                  to={{
                    pathname: '/signup/confirm',
                    search: `?email=${currentUser.attributes.email}&flow=update`,
                  }}
                >
                  Click here to verify
                </Link>
              </span>
            </Box>
          }
        />
      )}
      <Formik
        initialValues={initialValues}
        initialStatus={null as null | Error}
        onSubmit={async (
          values,
          { setSubmitting, setFieldError, setStatus },
        ) => {
          setSubmitting(true);
          setStatus(null);
          try {
            await Auth.updateUserAttributes(currentUser, {
              email: values.email,
              name: values.name,
              updated_at: `${Math.floor(new Date().valueOf() / 1000)}`,
            });

            await refresh();

            setSubmitting(false);
          } catch (err) {
            if (err.code === 'CodeMismatchException') {
              setFieldError('code', 'Invalid code submitted');
            } else {
              // Unhandled error, show it raw to the user
              setStatus(err);
            }

            setSubmitting(false);
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required('Required'),
          name: Yup.string().required('Required'),
        })}
      >
        {formikProps => {
          const {
            handleSubmit,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            status,
          } = formikProps;

          return (
            <form
              className={formClasses.form}
              noValidate
              onSubmit={handleSubmit}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                disabled={!!props.email}
                autoComplete="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={!!status || !!(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="name"
                label="Name"
                id="name"
                autoComplete="name"
                error={!!errors.name}
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                helperText={errors.name}
              />
              {status && (
                <Typography className={formClasses.globalError} variant="body2">
                  {status.message}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                className={formClasses.submitButton}
              >
                Update profile
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default EditProfile;
