import { Button, Link, TextField, Typography } from '@material-ui/core';
import Auth from '@aws-amplify/auth';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Done, Layout, useStyles } from './Layout';

const ForgottenPassword: React.FC<{
  email?: string;
}> = props => {
  const classes = useStyles();
  const [confirmationRecipient, setRecipient] = useState<null | string>(null);

  if (confirmationRecipient) {
    return (
      <Layout title="Forgotten password">
        <Done>
          <Typography align="justify" variant="body2">
            A confirmation code was sent to your email address.
            <br />
            This code is valid for 24 hours.
            <br />
            <Link
              to={{
                pathname: '/signin/password-reset',
                search: `?email=${confirmationRecipient}`,
              }}
              component={RouterLink}
            >
              Please visit the confirmation page after having received the
              confirmation code.
            </Link>
          </Typography>
        </Done>
      </Layout>
    );
  }

  return (
    <Layout title="Forgotten password">
      <Formik
        initialValues={{ email: props.email || '' }}
        initialStatus={null as null | Error}
        onSubmit={async (
          values,
          { setSubmitting, setFieldError, setStatus },
        ) => {
          setSubmitting(true);
          setStatus(null);
          try {
            await Auth.forgotPassword(values.email.toLowerCase());
            setSubmitting(false);
            setRecipient(values.email.toLowerCase());
          } catch (err) {
            // Unhandled error, show it raw to the user
            setStatus(err);

            setSubmitting(false);
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('A valid email is required')
            .required('Required'),
        })}
      >
        {formikProps => {
          const {
            handleSubmit,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            status,
          } = formikProps;

          return (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                disabled={!!props.email}
                autoComplete="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={!!status || !!(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              {status && (
                <Typography className={classes.globalError} variant="body2">
                  {status.message}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                className={classes.submitButton}
              >
                Reset password
              </Button>
            </form>
          );
        }}
      </Formik>
      <Link
        to={{
          pathname: '/signin/password-reset',
        }}
        component={RouterLink}
      >
        Already received a code by email ?
      </Link>
    </Layout>
  );
};

export default ForgottenPassword;
