import cx from 'clsx';
import { green, red } from '@material-ui/core/colors';
import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
  IconButton,
  Collapse,
  Divider,
  makeStyles,
  Theme,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import CrossIcon from '@material-ui/icons/Close';
import CheckedIcon from '@material-ui/icons/CheckBox';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank';

type Props = {
  authorized: boolean;
  title: string | React.ReactNode;
  subheader?: string | React.ReactNode;
  items?: Array<string>;
};

const colors = {
  success: green[800],
  error: red[500],
};

const useClasses = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: colors.error,
    color: theme.palette.getContrastText(colors.error),
  },
  avatarAuthorized: {
    backgroundColor: colors.success,
    color: theme.palette.getContrastText(colors.success),
  },
  subItemUnauthorized: {
    color: theme.palette.text.disabled,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const PermissionCard: React.FC<Props> = ({
  authorized,
  title,
  subheader,
  items,
}) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useClasses();

  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          items &&
          items.length && (
            <IconButton
              className={cx(classes.expand, expanded && classes.expandOpen)}
              aria-expanded={expanded}
              aria-label="Show-more"
              onClick={() => setExpanded(!expanded)}
            >
              <ExpandMoreIcon />
            </IconButton>
          )
        }
        avatar={
          <Avatar
            className={cx(
              classes.avatar,
              authorized && classes.avatarAuthorized,
            )}
          >
            {authorized ? <CheckIcon /> : <CrossIcon />}
          </Avatar>
        }
      />
      {items && items.length && (
        <Collapse in={expanded} timeout="auto">
          <Divider />
          <CardContent>
            <List dense={true}>
              {items.map((item, idx) => (
                <ListItem key={`${item}-${idx}`}>
                  <ListItemIcon>
                    {authorized ? (
                      <CheckedIcon />
                    ) : (
                      <UncheckedIcon color="disabled" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    className={cx(!authorized && classes.subItemUnauthorized)}
                    primary={item}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
};

export default PermissionCard;
