import {
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Button,
  Box,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { useControlRoomData } from './useControlRoomData';
import { useParams, useHistory, Redirect } from 'react-router';
import { pathPrefix } from './constants';
import Graph from './Graph';
import Center from '../Center';
import Loader from '../Loader';
import Error404 from '../pages/Error404';
import CenterTitle from './CenterTitle';
import moment from 'moment';
import RefreshIcon from '@material-ui/icons/Refresh';

const Dashboard: React.FC<{}> = () => {
  const { id } = useParams();
  const history = useHistory();

  const [data, actions] = useControlRoomData({ center: id, when: null });

  const theme = useTheme();
  const [exportOpen, setExportOpen] = useState(false);
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('sm'));

  if (!id) {
    return <Redirect to={`${pathPrefix}/LFEE`} />;
  }

  if (!data.content && data.loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  const notFound =
    data.error && 'code' in data.error && data.error.code === 'NoSuchKey';

  if (data.error && !notFound) {
    return (
      <React.Fragment>
        <Center>
          <Typography variant="h4">An error happened</Typography>
          {'code' in data.error && (
            <Typography variant="h6">{data.error.code}</Typography>
          )}
          <Typography>{data.error.message}</Typography>
        </Center>
      </React.Fragment>
    );
  }

  if (notFound || !data.content) {
    return <Error404 />;
  }

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="start"
      >
        <CenterTitle
          center={id}
          onSelectCenter={c => {
            history.push(`${pathPrefix}/${c}`);
          }}
          subtitle={
            !data.content ? null : (
              <React.Fragment>
                {moment.utc(data.content.when).format('YYYY/MM/DD HH:mm')}
                <IconButton disabled={data.loading} onClick={actions.refresh}>
                  <RefreshIcon />
                </IconButton>
              </React.Fragment>
            )
          }
        >
          {id}
        </CenterTitle>
        <Button
          disabled={data.loading}
          onClick={() => {
            setExportOpen(true);
          }}
          variant="outlined"
          color="primary"
        >
          Export
        </Button>
        <Dialog
          open={exportOpen}
          maxWidth="lg"
          fullWidth={true}
          fullScreen={fullScreenDialog}
          onClose={() => {
            setExportOpen(false);
          }}
        >
          <DialogTitle>Export</DialogTitle>
          <Divider />
          <DialogContent>
            {!data.content ? (
              'No data'
            ) : (
              <TextField
                variant="outlined"
                fullWidth
                multiline
                inputProps={{
                  spellCheck: false,
                }}
                value={JSON.stringify(data.content, null, 2)}
              />
            )}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                copy(JSON.stringify(data.content, null, 2));
              }}
              startIcon={<CopyIcon />}
            >
              Copy to clipboard
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setExportOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Graph loading={data.loading} data={data.content} center={id} />
    </React.Fragment>
  );
};

export default Dashboard;
