import { useEffect, useReducer, useMemo, useCallback } from 'react';
import { AWSError, useS3 } from '../hooks/useS3';
import { BUCKET, BUCKET_PREFIX } from './constants';
// import moment from 'moment';

type State = {
  loading: boolean;
  error: null | AWSError | Error;
  content: null | ControlRoomHistory;
  refreshCount: number;
};

export type ControlRoomHistory = {
  when: Date;
  controlRoomHistory: Array<ControlRoomHistoryItem>;
};

export type ControlRoomHistoryItem = {
  id: string;
  start: string;
  end: string;
  openSectors: number;
  sectors: Array<{ center: string; name: string }>;
};

type Action =
  | {
      type: 'start';
    }
  | { type: 'error'; error: AWSError | Error }
  | { type: 'end'; content: null | ControlRoomHistory }
  | { type: 'clear' }
  | { type: 'refresh' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'start':
      return {
        ...state,
        error: null,
        loading: true,
      };
    case 'error':
      return {
        ...state,
        content: null,
        error: action.error,
        loading: false,
      };
    case 'end': {
      return {
        ...state,
        error: null,
        loading: false,
        content: action.content,
      };
    }
    case 'clear': {
      return {
        error: null,
        loading: false,
        content: null,
        refreshCount: 0,
      };
    }
    case 'refresh': {
      return {
        ...state,
        refreshCount: state.refreshCount + 1,
      };
    }
  }
}

type HookOptions = {
  center: undefined | null | string;
  when: null | Date;
};

export function useControlRoomData({
  center,
  when,
}: HookOptions): [Omit<State, 'refreshCount'>, { refresh: () => void }] {
  const s3 = useS3();
  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    content: null,
    error: null,
    refreshCount: 0,
  });

  // const whenISO = when?.toISOString();

  const Key = useMemo(() => {
    if (!center) {
      return null;
    }

    return `${BUCKET_PREFIX}/${center}/latest.json`;
  }, [center]);

  const r = useMemo(
    () => ({
      ...state,
      loading: state.loading || s3.loading,
      error: state.error || s3.error || null,
    }),
    [s3.loading, s3.error, state],
  );

  useEffect(() => {
    let isMounted = true;
    if (!s3.client || !Key) {
      dispatch({ type: 'clear' });
      return;
    }

    dispatch({ type: 'start' });
    s3.client
      .getObject({
        Bucket: BUCKET,
        Key,
      })
      .promise()
      .then(res => {
        if (!isMounted) {
          return;
        }

        dispatch({
          type: 'end',
          content: res.Body
            ? { when: res.LastModified!, ...JSON.parse(res.Body.toString()) }
            : null,
        });
      })
      .catch(error => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'error', error });
      });

    return () => {
      isMounted = false;
    };
  }, [Key, s3.client, state.refreshCount]);

  const refresh = useCallback(() => dispatch({ type: 'refresh' }), [dispatch]);

  const actions = useMemo(() => ({ refresh }), [refresh]);

  return [r, actions] as [typeof r, typeof actions];
}
