import React from 'react';
import { Container, makeStyles, Theme, Box } from '@material-ui/core';
import { useAuthentication } from './Authentication';
import Content from './Content';
import TopBar from './TopBar';
import Loader from './Loader';
import Footer from './Footer';

const useClasses = makeStyles((theme: Theme) => ({
  loadingContainer: {
    flexDirection: 'column',
    display: 'flex',
    flexGrow: 1,
    overflowX: 'auto',
    overflowY: 'auto',
    flexShrink: 1,
  },
  container: {
    flexDirection: 'column',
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
    overflowX: 'auto',
    padding: 0,
  },
}));

const App: React.FC<{}> = () => {
  const { authState } = useAuthentication();
  const classes = useClasses();

  if (authState.authState === 'loading') {
    // Wait for the session to be loaded before doing anything
    return (
      <Container maxWidth="lg" className={classes.loadingContainer}>
        <Loader />
      </Container>
    );
  }

  // TODO: Handle authState.authState === 'error' case

  return (
    <React.Fragment>
      <TopBar />
      <Box
        display="flex"
        marginTop="80px"
        flexGrow={1}
        minHeight={600}
        flexDirection="column"
      >
        <Container className={classes.container}>
          <Content />
        </Container>
        <Footer />
      </Box>
    </React.Fragment>
  );
};

export default App;
