import { useS3 } from '../hooks/useS3';
import { useEffect, useReducer } from 'react';
import { BUCKET, BUCKET_PREFIX } from './constants';
import { notEmpty } from '../utils';
import moment from 'moment';

type AWSError = import('aws-sdk').AWSError;

type Input = {
  center: null | string;
  when: null | Date;
};

type State = {
  loading: boolean;
  error: null | Error | AWSError;
  records: null | Array<string>;
};

type Action =
  | {
      type: 'start';
    }
  | { type: 'error'; error: Error | AWSError }
  | { type: 'end'; records: null | Array<string> };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'start':
      return {
        ...state,
        error: null,
        loading: true,
      };
    case 'error':
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case 'end': {
      return {
        ...state,
        error: null,
        loading: false,
        records: action.records,
      };
    }
  }

  return state;
}

export function useTrafficVolumes({ center, when }: Input): State {
  const s3 = useS3();
  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    error: null,
    records: null,
  });

  const prefix =
    when && center
      ? `${BUCKET_PREFIX}/${center}/${moment.utc(when).format('YYYY/MM/DD/')}`
      : null;

  useEffect(() => {
    let isMounted = true;
    const unmount = () => {
      isMounted = false;
    };

    if (!s3.client || !prefix) {
      return unmount;
    }

    dispatch({ type: 'start' });

    s3.client
      .listObjects({
        Bucket: BUCKET,
        Prefix: prefix,
        Delimiter: '/',
      })
      .promise()
      .then(res => {
        if (!isMounted) {
          return;
        }

        if (!res || !res.CommonPrefixes) {
          dispatch({ type: 'end', records: null });
          return;
        }

        const records = res.CommonPrefixes.map(commonPrefix => {
          if (!commonPrefix.Prefix) {
            return null;
          }
          const r = commonPrefix.Prefix.replace(prefix, '').replace('/', '');

          if (r === '__all') {
            return null;
          }

          return r;
        }).filter(notEmpty);

        dispatch({ type: 'end', records });
      })
      .catch(error => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'error', error });
      });

    return unmount;
  }, [dispatch, s3.client, prefix]);

  if (s3.loading) {
    return {
      ...state,
      loading: true,
    };
  }

  if (s3.error) {
    return {
      ...state,
      error: s3.error,
    };
  }

  return state;
}
