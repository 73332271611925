import React, { useReducer, useEffect } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useS3 } from '../hooks/useS3';
import { BUCKET, BUCKET_PREFIX } from './constants';
import Loader from '../Loader';
import { notEmpty } from '../utils';

type AWSError = import('aws-sdk').AWSError;

type State = {
  loading: boolean;
  error: null | AWSError | Error;
  records: null | Array<string>;
};

type Action =
  | {
      type: 'start';
    }
  | { type: 'error'; error: AWSError | Error }
  | { type: 'end'; records: null | Array<string> };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'start':
      return {
        ...state,
        error: null,
        loading: true,
      };
    case 'error':
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case 'end': {
      return {
        ...state,
        error: null,
        loading: false,
        records: action.records,
      };
    }
  }

  return state;
}

const useClasses = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const CenterPicker: React.FC<{
  center: null | string;
  onChange: React.ComponentProps<typeof Select>['onChange'];
}> = ({ center, onChange }) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    error: null,
    records: null,
  });
  const s3 = useS3();

  const classes = useClasses();

  useEffect(() => {
    let isMounted = true;
    const unmount = () => {
      isMounted = false;
    };

    if (!s3.client) {
      return unmount;
    }

    dispatch({ type: 'start' });

    const prefix = `${BUCKET_PREFIX}/`;
    s3.client
      .listObjects({
        Bucket: BUCKET,
        Prefix: prefix,
        Delimiter: '/',
      })
      .promise()
      .then(res => {
        if (!isMounted) {
          return;
        }

        if (!res || !res.CommonPrefixes) {
          dispatch({ type: 'end', records: null });
          return;
        }

        const records = res.CommonPrefixes.map(commonPrefix => {
          if (!commonPrefix.Prefix) {
            return null;
          }
          const r = commonPrefix.Prefix.replace(prefix, '').replace('/', '');

          return r;
        }).filter(notEmpty);

        dispatch({ type: 'end', records });
      })
      .catch(error => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'error', error });
      });

    return unmount;
  }, [s3.client]);

  const error = s3.error || state.error;

  if (error) {
    if (
      'code' in error &&
      (error as AWSError).code === 'NotAuthorizedException'
    ) {
      throw s3.error || state.error;
    }

    return (
      <>
        <Typography variant="h6" gutterBottom>
          An error happened
        </Typography>
        <Typography variant="body1" paragraph>
          {error.message}
        </Typography>
      </>
    );
  }

  if (!s3.client || s3.loading || (state.loading && !state.records)) {
    return <Loader />;
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="center">Center</InputLabel>
      <Select
        value={center}
        onChange={onChange}
        inputProps={{
          name: 'center',
          id: 'center',
        }}
      >
        {state.records &&
          state.records.map(center => (
            <MenuItem key={center} value={center}>
              {center}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
export default CenterPicker;
