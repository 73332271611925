import React, { useMemo } from 'react';
import { SizeMe } from 'react-sizeme';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryLabel,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryScatter,
} from 'victory';
import moment from 'moment';

import { ControlRoomHistory } from './useControlRoomData';

type Props = {
  center: string;
  data: ControlRoomHistory;
  loading: boolean;
};

const Graph: React.FC<Props> = ({ center, loading, data }) => {
  const transformedData = useMemo(() => {
    if (!data || !data.controlRoomHistory) {
      return null;
    }

    return data.controlRoomHistory.flatMap(d => {
      return [
        {
          id: d.id,
          x: new Date(d.start),
          y: d.openSectors,
          start: d.start,
          end: d.end,
          sectors: d.sectors,
        },
        {
          id: d.id,
          x: d.end ? new Date(d.end) : moment.utc().toDate(),
          y: d.openSectors,
          start: d.start,
          end: d.end,
          sectors: d.sectors,
        },
      ];
    });
  }, [data]);

  const midPoints = useMemo(() => {
    if (!transformedData) {
      return null;
    }

    return transformedData.flatMap((curr, idx, arr) => {
      if (idx % 2 === 0) {
        return [];
      }

      const { x: _, ...rest } = curr;
      const distance =
        (curr.end ? new Date(curr.end) : new Date()).valueOf() -
        new Date(curr.start).valueOf();
      const middle = new Date(curr.start).valueOf() + distance / 2;
      const middleDate = new Date(middle);

      return {
        ...rest,
        x: middleDate,
      };
    });
  }, [transformedData]);

  if (!transformedData || !midPoints) {
    return null;
  }

  const maxOpenSectors = Math.max(...transformedData.map(({ y }) => y));

  return (
    <SizeMe>
      {({ size }) => (
        <VictoryChart
          height={size.height || 550}
          width={size.width || 350}
          theme={VictoryTheme.material}
          style={{
            parent: {
              opacity: loading ? 0.5 : 1,
            },
          }}
          minDomain={{ y: 0 }}
          maxDomain={{ y: maxOpenSectors + 1 }}
          containerComponent={
            <VictoryVoronoiContainer
              voronoiBlacklist={['sectorCountLine']}
              labelComponent={<VictoryTooltip dy={-7} constrainToVisibleArea />}
              labels={({ datum }) => {
                return `${moment.utc(datum.start).format('HH:mm')} => ${
                  !datum.end ? 'NOW' : moment.utc(datum.end).format('HH:mm')
                }

                ${datum.sectors
                  .map(({ name }: { center: string; name: string }) => name)
                  .sort()
                  .join('\n')}`;
              }}
            />
          }
        >
          <VictoryAxis
            tickFormat={t => {
              return moment.utc(t).format('HH:mm');
            }}
            tickCount={8}
            scale="time"
            tickLabelComponent={
              <VictoryLabel
                textAnchor="end"
                verticalAnchor="middle"
                angle={-90}
              />
            }
          />
          <VictoryAxis dependentAxis tickFormat={t => `${t}`} />
          <VictoryLine name="sectorCountLine" data={transformedData} />
          <VictoryScatter
            style={{ data: { fill: 'transparent' } }}
            data={midPoints}
          />
        </VictoryChart>
      )}
    </SizeMe>
  );
};

export default Graph;
