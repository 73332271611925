import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  useMediaQuery,
  Divider,
  useTheme,
} from '@material-ui/core';

import { ToggleButtonGroup } from '@material-ui/lab';

import NavigatePrev from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import CopyIcon from '@material-ui/icons/FileCopy';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { ButtonLink, IconButtonLink, ToggleButtonLink } from '../ButtonLink';
import Center from '../Center';
import Loader from '../Loader';
import Graph from './Graph';
import TVTitle from './TVTitle';
import copy from 'copy-to-clipboard';
import { useGraphData } from './useGraphData';
import { useHistory } from 'react-router';
import queryString from 'query-string';

type Props = {
  center: string;
  trafficVolume: string;
  when: Date | Moment;
  compare: null | Date | Moment;
};

const View: React.FC<Props> = ({ center, trafficVolume, when, compare }) => {
  const history = useHistory();

  const [baseData] = useGraphData({
    center,
    trafficVolume,
    when: !!when ? moment.utc(when).toDate() : null,
  });

  const [compareData] = useGraphData({
    center,
    trafficVolume,
    when: !!compare ? moment.utc(compare).toDate() : null,
  });

  const loading = baseData.loading || compareData.loading;

  const theme = useTheme();
  const [exportOpen, setExportOpen] = useState(false);
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('sm'));
  const fullNavigationButtons = useMediaQuery(theme.breakpoints.up('sm'));

  if (loading && !baseData.content) {
    return (
      <React.Fragment>
        <Typography variant="h3">{trafficVolume}</Typography>
        <Loader />
      </React.Fragment>
    );
  }

  const notFound =
    baseData.error &&
    'code' in baseData.error &&
    baseData.error.code === 'NoSuchKey';

  if (baseData.error && !notFound) {
    return (
      <React.Fragment>
        <TVTitle when={moment.utc(when).toDate()} center={center}>
          {trafficVolume}
        </TVTitle>
        <Center>
          <Typography variant="h4">An error happened</Typography>
          {'code' in baseData.error && (
            <Typography variant="h6">{baseData.error.code}</Typography>
          )}
          <Typography>{baseData.error.message}</Typography>
        </Center>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="baseline"
        flexWrap="wrap"
      >
        <TVTitle
          when={moment.utc(when).toDate()}
          onSelectTv={tv => {
            if (trafficVolume === tv) {
              return;
            }

            history.push({
              search: queryString.stringify({
                center,
                trafficVolume: tv,
                when: when.toISOString(),
              }),
            });
          }}
          center={center}
          subtitle="Entry counts"
        >
          {trafficVolume}
        </TVTitle>
        <Button
          disabled={loading}
          onClick={() => {
            setExportOpen(true);
          }}
          variant="outlined"
          color="primary"
        >
          Export
        </Button>
        <Dialog
          open={exportOpen}
          maxWidth="lg"
          fullWidth={true}
          fullScreen={fullScreenDialog}
          onClose={() => {
            setExportOpen(false);
          }}
        >
          <DialogTitle>Export</DialogTitle>
          <Divider />
          <DialogContent>
            {!baseData.content ? (
              'No data'
            ) : (
              <TextField
                variant="outlined"
                fullWidth
                multiline
                inputProps={{
                  spellCheck: false,
                }}
                value={JSON.stringify(baseData.content, null, 2)}
              />
            )}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                copy(JSON.stringify(baseData.content, null, 2));
              }}
              startIcon={<CopyIcon />}
            >
              Copy to clipboard
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setExportOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {loading && !baseData.content ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Box
            display="flex"
            marginTop={1}
            marginBottom={1}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {fullNavigationButtons ? (
              <ButtonLink
                disabled={loading}
                color="primary"
                variant="outlined"
                to={{
                  search: queryString.stringify({
                    ...queryString.parse(history.location.search),
                    when: moment
                      .utc(when)
                      .subtract(20, 'minutes')
                      .toISOString(),
                  }),
                }}
                startIcon={<NavigatePrev />}
              >
                Prev
              </ButtonLink>
            ) : (
              <IconButtonLink
                disabled={loading}
                color="primary"
                to={{
                  search: queryString.stringify({
                    ...queryString.parse(history.location.search),
                    when: moment
                      .utc(when)
                      .subtract(20, 'minutes')
                      .toISOString(),
                  }),
                }}
              >
                <NavigatePrev />
              </IconButtonLink>
            )}
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6">
                {moment.utc(when).format('YYYY-MM-DD @ HH:mm')}
              </Typography>
              <ToggleButtonGroup
                exclusive={true}
                value={queryString.parse(history.location.search).compare || ''}
                size="small"
              >
                <ToggleButtonLink
                  value="1d"
                  to={{
                    search: queryString.stringify({
                      ...queryString.parse(history.location.search),
                      compare: '1d',
                    }),
                  }}
                >
                  1d
                </ToggleButtonLink>
                <ToggleButtonLink
                  value="7d"
                  to={{
                    search: queryString.stringify({
                      ...queryString.parse(history.location.search),
                      compare: '7d',
                    }),
                  }}
                >
                  7d
                </ToggleButtonLink>
                <ToggleButtonLink
                  value="28d"
                  to={{
                    search: queryString.stringify({
                      ...queryString.parse(history.location.search),
                      compare: '28d',
                    }),
                  }}
                >
                  28d
                </ToggleButtonLink>
                <ToggleButtonLink
                  value="CLEAR"
                  to={{
                    search: queryString.stringify({
                      ...queryString.parse(history.location.search),
                      compare: undefined,
                    }),
                  }}
                >
                  Clear
                </ToggleButtonLink>
              </ToggleButtonGroup>
            </Box>
            {fullNavigationButtons ? (
              <ButtonLink
                disabled={loading}
                color="primary"
                variant="outlined"
                to={{
                  search: queryString.stringify({
                    ...queryString.parse(history.location.search),
                    when: moment
                      .utc(when)
                      .add(20, 'minutes')
                      .toISOString(),
                  }),
                }}
                startIcon={<NavigateNext />}
              >
                Prev
              </ButtonLink>
            ) : (
              <IconButtonLink
                disabled={loading}
                color="primary"
                to={{
                  search: queryString.stringify({
                    ...queryString.parse(history.location.search),
                    when: moment
                      .utc(when)
                      .add(20, 'minutes')
                      .toISOString(),
                  }),
                }}
              >
                <NavigateNext />
              </IconButtonLink>
            )}
          </Box>
          <Graph
            loading={loading}
            content={baseData.content}
            compare={compareData.content}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default View;
