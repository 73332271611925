import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';
import GroupIcon from '@material-ui/icons/GroupTwoTone';
import HelpIcon from '@material-ui/icons/HelpTwoTone';
import BarChartIcon from '@material-ui/icons/InsertChartTwoTone';
import React from 'react';
import { ButtonLink } from '../ButtonLink';

const useClasses = makeStyles((theme: Theme) => ({
  icon: {
    width: 'auto',
    height: 'auto',
    maxWidth: 250,
    margin: `${theme.spacing(2)}px auto`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const HomePage: React.FC<{}> = () => {
  const classes = useClasses();

  return (
    <React.Fragment>
      <Typography
        className={classes.title}
        component="h1"
        variant="h2"
        align="center"
      >
        Welcome on the DSNA E-CDM Portal
      </Typography>

      <Box display="flex" flexDirection="column" padding={2}>
        <Typography
          className={classes.subtitle}
          variant="h4"
          color="primary"
          component="h2"
          align="right"
        >
          What's on this website ?
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" component="h3" gutterBottom>
                  Entry counts archives
                </Typography>
                <BarChartIcon className={classes.icon} color="primary" />
                <Typography variant="subtitle1" align="center" paragraph>
                  Explore periodic entry count snapshots on DSNA traffic volumes
                </Typography>
              </CardContent>
              <CardActions>
                <ButtonLink to="/about/archive-explorer">Learn more</ButtonLink>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" component="h3" gutterBottom>
                  E-CDM portal management
                </Typography>
                <GroupIcon className={classes.icon} color="primary" />
                <Typography variant="subtitle1" align="center" paragraph>
                  Manage E-CDM users
                </Typography>
              </CardContent>
              <CardActions>
                <ButtonLink to="/about/ecdm-portal">Learn more</ButtonLink>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" component="h3" gutterBottom>
                  And more to come !
                </Typography>
                <HelpIcon className={classes.icon} color="action" />
                <Typography variant="subtitle1" align="center" paragraph>
                  ... if this product is a success
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default HomePage;
