import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Loader from '../Loader';
import { useCognitoUserPool } from './hooks/useCognitoUserPool';

const Dashboard: React.FC<{}> = () => {
  const userPool = useCognitoUserPool();

  if (userPool.loading && !userPool.response) {
    return <Loader />;
  }

  if (userPool.error) {
    if (
      'code' in userPool.error &&
      userPool.error.code === 'NotAuthorizedException'
    ) {
      throw userPool.error;
    }

    return (
      <>
        <Typography variant="h6" gutterBottom>
          An error happened
        </Typography>
        <Typography variant="body1" paragraph>
          {userPool.error.message}
        </Typography>
      </>
    );
  }

  if (!userPool.response || !userPool.response.UserPool) {
    return (
      <>
        <Typography variant="h6" gutterBottom>
          An error happened
        </Typography>
        <Typography variant="body1" paragraph>
          The user pool could not be found.
        </Typography>
      </>
    );
  }

  return (
    <React.Fragment>
      <Typography variant="h2" gutterBottom>
        E-CDM Portal management
      </Typography>
      <div style={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Card>
              <CardHeader title="User count" />
              <CardContent>
                <Typography variant="h1" align="center" component="div">
                  {userPool.response.UserPool.EstimatedNumberOfUsers}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
