import React from 'react';
import { Typography, Box, Link, makeStyles, Theme } from '@material-ui/core';
import b2bProxyImage from './B2BProxy.png';

const useClasses = makeStyles((theme: Theme) => ({
  link: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  image: {
    maxWidth: '100%',
    width: 600,
  },
}));

const B2BProxy = () => {
  const classes = useClasses();

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" margin={1}>
        <Typography variant="h3" gutterBottom>
          B2B Proxy
        </Typography>
        <Typography variant="caption" align="center" gutterBottom>
          <Link
            className={classes.link}
            href={b2bProxyImage}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.image}
              src={b2bProxyImage}
              alt="B2B Proxy architecture"
            />
          </Link>
          <br />
          B2B proxy architecture
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default B2BProxy;
