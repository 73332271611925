import React, { useMemo, useReducer, useEffect } from 'react';
import moment, { Moment } from 'moment';
import Loader from '../Loader';
import { useS3 } from '../hooks/useS3';
import { Calendar } from '@material-ui/pickers';
import { BUCKET, BUCKET_PREFIX } from './constants';
import { notEmpty } from '../utils';

type State = {
  loading: boolean;
  error: null | Error;
  records: null | { [key: string]: Array<string> };
};

type Action =
  | {
      type: 'start';
    }
  | { type: 'error'; error: Error }
  | { type: 'clear' }
  | { type: 'end'; month: string; days: Array<string> };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'start':
      return {
        ...state,
        error: null,
        loading: true,
      };
    case 'error':
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case 'end': {
      return {
        ...state,
        error: null,
        loading: false,
        records: {
          ...state.records,
          [action.month]: action.days,
        },
      };
    }
    case 'clear':
      return {
        loading: false,
        error: null,
        records: {},
      };
  }

  return state;
}

export const DatePicker: React.FC<{
  date: Moment | null;

  center: string;
  onChange: (date: null | Moment, isFinish?: boolean | undefined) => void;
}> = ({ date, center, onChange }) => {
  const s3 = useS3();
  const [cache, dispatch] = useReducer(reducer, {
    loading: true,
    error: null,
    records: null,
  });

  const loadMonth = useMemo(() => {
    // TODO: Implement some form of caching
    return async (date: Moment) => {
      if (!s3.client || !center) {
        return;
      }

      dispatch({ type: 'start' });
      const month = date.format('YYYY/MM');
      const prefix = `${BUCKET_PREFIX}/${center}/${month}/`;

      const objects = await s3.client
        .listObjects({
          Bucket: BUCKET,
          Prefix: prefix,
          Delimiter: '/',
        })
        .promise();

      const days = (objects.CommonPrefixes || [])
        .map(commonPrefix => {
          if (!commonPrefix.Prefix) {
            return null;
          }
          const r = commonPrefix.Prefix.replace(prefix, '').replace('/', '');

          return r;
        })
        .filter(notEmpty);

      dispatch({ type: 'end', month, days });
    };
  }, [dispatch, s3.client, center]);

  useEffect(() => {
    if (!center) {
      return;
    }

    loadMonth(date || moment.utc());
  }, [loadMonth, date, center]);

  if (!s3.client || s3.loading || (cache.loading && !cache.records)) {
    return <Loader />;
  }

  return (
    <Calendar
      date={date}
      disableFuture={true}
      onMonthChange={async date => {
        if (!date || !loadMonth) {
          return;
        }

        const month = date.format('YYYY/MM');
        if (cache.records && cache.records[month]) {
          return;
        }

        await loadMonth(date);
      }}
      onChange={onChange}
      shouldDisableDate={date => {
        if (!date || !cache.records) {
          return false;
        }

        const month = date.format('YYYY/MM');
        const day = date.format('DD');

        if (!cache.records[month]) {
          return true;
        }

        if (!cache.records[month].includes(day)) {
          return true;
        }

        return false;
      }}
    />
  );
};

export default DatePicker;
