import { Box } from '@material-ui/core';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useAuthentication } from '../Authentication';
import Error404 from '../pages/Error404';
import { pathPrefix } from './constants';
import Dashboard from './Dashboard';
import View from './View';

export { pathPrefix } from './constants';

export const Index: React.FC<{}> = () => {
  // Authenticated only route
  const { authState } = useAuthentication();
  if (authState.authState !== 'signedIn') {
    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column" flexGrow={1} padding={1}>
        <Switch>
          <Route path={`${pathPrefix}`} exact component={Dashboard} />
          <Route
            path={`${pathPrefix}/view`}
            render={({ match, location }) => {
              const search = queryString.parse(location.search);

              if (
                !search.center ||
                !search.trafficVolume ||
                !search.when ||
                !moment.utc(search.when).isValid()
              ) {
                return <Redirect to={`${pathPrefix}`} />;
              }

              let compare = null;

              if (search.compare && typeof search.compare === 'string') {
                const matches = search.compare.match(/^(\d+)d$/i);

                if (matches && matches[1] && parseInt(matches[1], 10)) {
                  compare = moment
                    .utc(search.when)
                    .subtract(parseInt(matches[1], 10), 'days')
                    .toDate();
                }
              }

              return (
                <View
                  center={`${search.center}`}
                  trafficVolume={`${search.trafficVolume}`}
                  when={moment.utc(search.when).toDate()}
                  compare={compare}
                />
              );
            }}
          />
          <Route component={Error404} />
        </Switch>
      </Box>
    </React.Fragment>
  );
};
