import { Button, Grid, Link, TextField, Typography } from '@material-ui/core';
import Auth from '@aws-amplify/auth';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuthentication } from '../Authentication';
import { Done, Layout, useStyles } from './Layout';

const ConfirmSignUp: React.FC<{
  email?: string;
  flow?: 'signup' | 'update';
}> = ({ email, flow = 'signup' }) => {
  const classes = useStyles();
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const { refresh } = useAuthentication();

  if (confirmed) {
    return (
      <Layout title="Confirm your email">
        <Done
          redirect={{
            pathname: flow === 'signup' ? '/signin' : '/',
          }}
        >
          Your email is now confirmed.
          <br />
          {flow === 'signup' ? (
            <Link
              to={{
                pathname: '/signin',
              }}
              component={RouterLink}
            >
              Please sign in using your credentials on the sign in page.
            </Link>
          ) : (
            <Link
              to={{
                pathname: '/',
              }}
              component={RouterLink}
            >
              Return to the application
            </Link>
          )}
        </Done>
      </Layout>
    );
  }

  return (
    <Layout title="Confirm your email">
      {email && (
        <Typography color="textSecondary" variant="subtitle2">
          An email was sent to {email} with a confirmation code.
        </Typography>
      )}
      <Formik
        initialValues={{ email: email || '', code: '' }}
        initialStatus={null as null | Error}
        onSubmit={async (
          values,
          { setSubmitting, setFieldError, setStatus },
        ) => {
          setSubmitting(true);
          setStatus(null);
          try {
            if (flow === 'signup') {
              await Auth.confirmSignUp(values.email.toLowerCase(), values.code);
            } else {
              await Auth.verifyCurrentUserAttributeSubmit('email', values.code);
              await refresh();
            }

            setSubmitting(false);
            setConfirmed(true);
          } catch (err) {
            if (err.code === 'UserNotFoundException') {
              setFieldError('email', 'User not found');
            } else if (err.code === 'UsernameExistsException') {
              setFieldError('email', err.message);
            } else if (err.code === 'CodeMismatchException') {
              setFieldError('code', 'Invalid verification code provided');
            } else if (err.code === 'NotAuthorizedException') {
              setStatus(new Error('Email is already confirmed'));
            } else {
              // Unhandled error, show it raw to the user
              setStatus(err);
            }

            setSubmitting(false);
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('A valid email is required')
            .required('Required'),
          code: Yup.string().required('Required'),
        })}
      >
        {formikProps => {
          const {
            handleSubmit,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            dirty,
            isSubmitting,
            isValid,
            status,
          } = formikProps;

          return (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                disabled={!!email}
                autoComplete="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={!!status || !!(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="code"
                label="Confirmation code"
                id="code"
                autoComplete="new-password"
                error={!!status || !!(touched.code && errors.code)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.code}
                helperText={touched.code && errors.code}
              />
              {status && (
                <Typography className={classes.globalError} variant="body2">
                  {status.message}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!dirty || isSubmitting || !isValid}
                className={classes.submitButton}
              >
                Confirm your email
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: '/signup/resend-confirmation',
                      search: `?email=${values.email.toLowerCase()}`,
                    }}
                  >
                    Resend confirmation code ?
                  </Link>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default ConfirmSignUp;
