import { AdminListGroupsForUserResponse } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { Auth as authConstants } from '../../constants';
import { createReducer, State } from './reducer';
import { useCognito } from './useCognito';

type Input = {
  username?: null | undefined | string;
};

const userGroupsReducer = createReducer<AdminListGroupsForUserResponse>();

export function useCognitoUserGroups({
  username,
}: Input): State<AdminListGroupsForUserResponse> & { refresh: () => any } {
  const cognito = useCognito();
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [state, dispatch] = useReducer(userGroupsReducer, {
    loading: true,
    error: null,
    response: null,
  } as State<AdminListGroupsForUserResponse>);

  useEffect(() => {
    let isMounted = true;
    const unmount = () => {
      isMounted = false;
    };
    if (!cognito.client || !username) {
      return unmount;
    }

    dispatch({ type: 'loading' });

    cognito.client
      .adminListGroupsForUser({
        UserPoolId: authConstants.userPoolId,
        Username: username,
      })
      .promise()
      .then(response => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'response', response });
      })
      .catch(error => {
        if (!isMounted) {
          return;
        }

        dispatch({ type: 'error', error });
      });

    return unmount;
  }, [dispatch, cognito.client, username, refreshCounter]);

  const refresh = useCallback(() => setRefreshCounter(i => i + 1), [
    setRefreshCounter,
  ]);

  if (cognito.loading) {
    return {
      ...state,
      refresh,
      loading: true,
    };
  }

  if (cognito.error) {
    return {
      ...state,
      refresh,
      error: cognito.error,
    };
  }

  return {
    ...state,
    refresh,
  };
}

const userGroupModificationReducer = createReducer<
  'removeSuccess' | 'addSuccess'
>();

type UserGroupActions = {
  addGroup: (groupName: string) => Promise<void>;
  removeGroup: (groupName: string) => Promise<void>;
};

export function useCognitoUserGroupChange({
  username,
}: Input): State<'removeSuccess' | 'addSuccess'> & UserGroupActions {
  const cognito = useCognito();

  const [state, dispatch] = useReducer(userGroupModificationReducer, {
    loading: false,
    error: null,
    response: null,
  } as State<'removeSuccess' | 'addSuccess'>);

  const addGroup = useCallback(
    async (GroupName: string) => {
      if (!cognito.client || !username) {
        return;
      }

      dispatch({ type: 'loading' });
      try {
        await cognito.client
          .adminAddUserToGroup({
            UserPoolId: authConstants.userPoolId,
            Username: username,
            GroupName,
          })
          .promise();

        dispatch({ type: 'response', response: 'addSuccess' });
      } catch (error) {
        dispatch({ type: 'error', error });
      }
    },
    [cognito.client, username, dispatch],
  );

  const removeGroup = useCallback(
    async (GroupName: string) => {
      if (!cognito.client || !username) {
        return;
      }

      dispatch({ type: 'loading' });
      try {
        await cognito.client
          .adminRemoveUserFromGroup({
            UserPoolId: authConstants.userPoolId,
            Username: username,
            GroupName,
          })
          .promise();

        dispatch({ type: 'response', response: 'removeSuccess' });
      } catch (error) {
        dispatch({ type: 'error', error });
      }
    },
    [cognito.client, username, dispatch],
  );

  if (cognito.loading) {
    return {
      ...state,
      addGroup,
      removeGroup,
      loading: true,
    };
  }

  if (cognito.error) {
    return {
      ...state,
      addGroup,
      removeGroup,
      error: cognito.error,
    };
  }

  return {
    ...state,
    addGroup,
    removeGroup,
  };
}
