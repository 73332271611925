import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Loader from '../Loader';
import {
  useCognitoUserGroupChange,
  useCognitoUserGroups,
} from './hooks/useCognitoUserGroups';
import { useCognitoUserPoolGroups } from './hooks/useCognitoUserPoolGroups';

const UserGroups: React.FC<{ username: string | null | undefined }> = ({
  username,
}) => {
  const userGroups = useCognitoUserGroups({ username });
  const userPoolGroups = useCognitoUserPoolGroups();
  const userGroupChanges = useCognitoUserGroupChange({ username });

  if ((userGroups.loading && !userGroups.response) || userPoolGroups.loading) {
    return <Loader />;
  }

  if (
    userGroups.error ||
    userPoolGroups.error ||
    !userGroups.response ||
    !userPoolGroups.response
  ) {
    return <Typography variant="h3">An error happened ...</Typography>;
  }

  const knownGroups = userPoolGroups.response.Groups || [];
  const memberships = (userGroups.response.Groups || []).map(
    ({ GroupName }) => GroupName,
  );

  return (
    <React.Fragment>
      <FormControl component="fieldset" error={!!userGroupChanges.error}>
        <FormLabel component="legend">Groups</FormLabel>
        <FormGroup>
          {knownGroups.map(({ GroupName, Description }) => (
            <Tooltip title={Description} key={GroupName}>
              <FormControlLabel
                disabled={userGroupChanges.loading || userGroups.loading}
                checked={
                  !!memberships.find(membership => membership === GroupName)
                }
                onChange={(event: React.ChangeEvent<any>) => {
                  if (!GroupName) {
                    return;
                  }

                  const checked = event.target.checked;
                  if (!checked) {
                    // Add user to group
                    userGroupChanges
                      .removeGroup(GroupName)
                      .then(() => userGroups.refresh());
                  } else {
                    userGroupChanges
                      .addGroup(GroupName)
                      .then(() => userGroups.refresh());
                  }
                }}
                control={<Checkbox value={GroupName} />}
                label={GroupName}
              />
            </Tooltip>
          ))}
        </FormGroup>
        {userGroupChanges.error && (
          <FormHelperText>{userGroupChanges.error.message}</FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
};

export default UserGroups;
