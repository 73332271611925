import React, { useState } from 'react';
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Box,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const useClasses = makeStyles((theme: Theme) => ({
  editIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const centers = ['LFEE', 'LFMM'];

const CenterTitle: React.FC<{
  center: string;
  onSelectCenter?: (center: string) => any;
  subtitle?: React.ReactNode;
}> = ({ center, children, onSelectCenter, subtitle }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useClasses();

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h3" gutterBottom={!subtitle}>
        {children}
        {onSelectCenter && (
          <React.Fragment>
            <IconButton
              aria-controls="tv-selection-menu"
              aria-haspopup="true"
              onClick={event => setAnchorEl(event.currentTarget)}
              className={classes.editIcon}
            >
              <EditIcon />
            </IconButton>
            <Menu
              id="tv-selection-menu"
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              {centers.map(c => (
                <MenuItem
                  key={c}
                  onClick={() => {
                    setAnchorEl(null);
                    onSelectCenter(c);
                  }}
                  selected={c === center}
                >
                  {c}
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        )}
      </Typography>
      {subtitle && (
        <Typography color="textSecondary" variant="h5" gutterBottom>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default CenterTitle;
